import React, {Fragment} from 'react';
import {Query} from "react-apollo";

import queries from "../queries";

class ScreenTypeSelector extends React.Component {
  state = {};

  render() {
    return (
      <Query query={queries.screenTypes.list}>
        {({ loading, error, data }) => {
          if (loading) return 'Loading...';
          if (error) return `Error! ${error.message}`;

          const {screenTypes} = data;
          const screenType = !!this.state.screenType ? this.state.screenType : screenTypes[0];

          return (
            <Fragment>
              <select value={screenType.id} onChange={(e) => {
                const screenTypeId = e.target.value;
                const newScreenType =  screenTypes.filter((st) => st.id === screenTypeId)[0];
                this.setState({screenType: newScreenType});
              }}>
                {screenTypes.map((screenType) => (
                  <option key={screenType.id} value={screenType.id}>{screenType.name}</option>
                ))}
              </select>

              {this.props.children(screenType)}
            </Fragment>
          );
        }}
      </Query>
    )
  }
}

export default ScreenTypeSelector;
