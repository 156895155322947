import cells from './cells';
import * as pages from './pages';
import * as projects from './projects';
import * as fonts from './fonts';
import * as languages from './languages';
import * as tabs from './tabs';
import * as screenTypes from './sreentypes';
import * as products from './products';
import * as categories from './categories';
import * as displays from './displays';
import * as games from './games';
import form_fields from './form_fields';
import * as agenda_category from './agenda_category';
import * as photoDatas from './photoDatas';

export default {
  projects,
  pages,
  cells,
  fonts,
  languages,
  tabs,
  screenTypes,
  products,
  categories,
  form_fields,
  displays,
  games,
  agenda_category,
  photoDatas,
}
