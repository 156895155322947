import React from "react";
import _ from "lodash";
import {FormattedMessage} from "react-intl";
import RichTextEditor from "react-rte";
import {Mutation} from "react-apollo";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Select from "react-select";

import RichTextContentEditor from "../cells/editors/RichTextContentEditor";
import textInputDialog from "../dialogs/TextInputDialog";
import periodsPickerDialog from "../dialogs/PeriodsPickerDialog";
import queries from "../../queries";
import ImageUploader from "../uploaders/ImageUploader";

import '../../style/ProductsList.scss';
import {apolloClient} from "../../config";
import moment from "moment";
import confirmDialog from "../dialogs/ConfirmDialog";

class ProductEdition extends React.Component {
  state = {
    pristine: true,
    pristineCategories: true,
  };

  deleteDialogConfirmationContent = (
    <FormattedMessage id={'product.delete.confirmation.dialog.message'} values={{br: <br/>}} />
  );

  componentDidUpdate(prevProps) {
    if (!this.state.pristine) return;
    if (!_.isEqual(this.props.product, prevProps.product)) {
      this.setState({product: this.props.product});
    }
  }

  componentDidMount() {
    this.setState({product: this.props.product, editMode: this.props.temp});
  }

  changeCategories(categories) {
    const { product } = this.state;

    this.setState({
      pristine: false,
      pristineCategories: false,
      product: {
        ...product,
        categories,
      }
    });
  }

  setNewValue(fieldName, fieldValue, values) {
    const { language } = this.props;
    const { product } = this.state;
    const newProduct = !language ? {...product, [fieldName]: fieldValue} :
      { ...product,
        i18n: [
          ..._.reject(product.i18n, {language_code: language.code}),
          {...values, [fieldName]: fieldValue, pristine: false}
        ]
      };
    console.log('New product: ', newProduct);
    this.setState({product: newProduct, pristine: false});
  }

  render() {
    const { temp, onSave, project, language, categoriesFilter = [] } = this.props;
    const { product, pristine, pristineCategories, editMode } = this.state;

    if (!product) return null;

    const values = !!language ? _.filter(product.i18n, {language_code: language.code})[0] || {language_code: language.code} : product;

    if (!editMode) return (
      <div className={'tsk-product-edit-container'}>
        {!!values.small_image_uri &&
        <div className={"tsk-product-edit-img"}>
          <img src={values.small_image_uri} alt={"product-thumbnail"}/>
        </div>
        }
        {(!values.small_image_uri && !!product.small_image_uri) &&
        <div className={"tsk-product-edit-img"}>
          <img src={product.small_image_uri} alt={"product-thumbnail"}/>
        </div>
        }
        <div className={"tsk-product-edit-content"}>
          <p className={`tsk-product-edit-title`}>
            {!!values.name &&
            <span>{values.name}</span>
            }
            {!values.name &&
            <span className={'tsk-product-default-language'}>{product.name}</span>
            }
            {!!values.price &&
            <span className={'tsk-product-edit-price'}>{values.price}</span>
            }
            {(!values.price && !!product.price ) &&
            <span className={'tsk-product-edit-price tsk-product-default-language'}>{product.price}</span>
            }
          </p>
          <p className={"tsk-product-edit-description"}>{product.categories.map(c => c.name).join(', ')}</p>
        </div>

        <button type={'button'} className={'tsk-button tsk-product-edit-button'}  onClick={() => this.setState({editMode: true})}>
          <FormattedMessage id={'product.edit'} />
        </button>
      </div>
    );


    const mutation = temp ? queries.products.createProduct : queries.products.update;

    const canSave = !(pristine || !product.name || product.name.length ===0);

    const longTxtValue = (values.text_data !== undefined) ?
      values.text_data : (
        !!values.long_description ?
          RichTextEditor.createValueFromString(JSON.stringify(values.long_description), 'raw') :
          RichTextEditor.createEmptyValue()
      );

    return (
      <div className={'tsk-product-content'}>
        <ImageUploader value={product.small_image_uri} canDelete={true} onUpload={(imageUri) =>
          this.setState({product: {...product, small_image_uri: imageUri}, pristine: false})
        }
        onDelete={() =>
          this.setState({product: {...product, small_image_uri: null}, pristine: false})
        }/>
        <div className={'tsk-multi-input-block'}>
          <div className={'tsk-input-line'}>
            <div className={'tsk-input-block'}>
              <label><FormattedMessage id={'product.name'} /></label>
              <input value={values.name || ''} onChange={(e) =>
                this.setNewValue("name", e.target.value, values)
              }
              placeholder={product.name}/>
            </div>
            <div />
            <div className={'tsk-input-block'}>
              <label><FormattedMessage id={'product.price'} /></label>
              <input value={values.price || ''} onChange={(e) =>
                this.setNewValue("price", e.target.value, values)
              }
              placeholder={product.price}/>
            </div>
          </div>
          <div className={'tsk-input-block'}>
            <label><FormattedMessage id={'product.short_description'} /></label>
            <input className={'tsk-whole-line'}
              value={values.short_description || ''}
              onChange={(e) =>
                this.setNewValue("short_description", e.target.value, values)
              }
              placeholder={product.short_description}/>
          </div>
        </div>
        <div className={'tsk-input-block'}>
          <label><FormattedMessage id={'product.long_description'} /></label>
          <RichTextContentEditor
            value={longTxtValue}
            onChange={(value) =>
              this.setNewValue("text_data", value, values)
            }
            project={project}
          />
        </div>
        <ImageUploader value={product.large_image_uri} canDelete={true} onUpload={(imageUri) =>
          this.setState({product: {...product, large_image_uri: imageUri}, pristine: false})
        }
        onDelete={() =>
          this.setState({product: {...product, large_image_uri: null}, pristine: false})
        }/>
        <div>
          <Mutation
            mutation={queries.categories.create}
            refetchQueries={() => [
              { query: queries.projects.getWithChildren, variables: {id: project.id} }
            ]}
          >
            {(createCategory) => (
              <button type={'button'} className={'tsk-button tsk-link'} onClick={async () => {
                const categoryName = await textInputDialog({
                  labelId: 'category.new.input.label'
                });
                const {data: {createCategory: cat }} = await createCategory({ variables:{ input: {project_id: project.id, name: categoryName}}});
                this.changeCategories([...product.categories, cat]);
              }}>
                <FontAwesomeIcon icon={'plus'}/>&nbsp;
                <FormattedMessage id={'category.new.button.label'} />
              </button>
            )}
          </Mutation>
          <div className={'tsk-category-list'}>
            <Select
              isClearable={true}
              isMulti={true}
              getOptionLabel={(category) => category.name}
              getOptionValue={(category) => category.id}
              value={product.categories}
              onChange={this.changeCategories.bind(this)}
              options={project.categories}
            />
          </div>
        </div>
          <div className={'tsk-product-save-container'}>
          {canSave &&
          <Mutation
            mutation={mutation}
            update={(cache) => {
              Object.keys(cache.data.data.ROOT_QUERY).forEach((key) => {
                if(key.match(/^productsForProjectId/)) {
                  delete cache.data.data.ROOT_QUERY[key];
                }
              });
              cache.data.depend.dirty('ROOT_QUERY');
              cache.optimisticData.depend.dirty('ROOT_QUERY');
            }}
            refetchQueries={() => [
              {
                query: queries.products.listForProjectId,
                variables: {
                  project_id: project.id,
                  category_ids: categoriesFilter.map(c => c.id),
                }
              },
              { query: queries.projects.getWithChildren, variables: {id: project.id} }
            ]}
          >
            {(saveProduct) => (
              <button type={'button'} className={'tsk-button'} onClick={async () => {
                const inputValues = {
                  id: product.id,
                  small_image_uri: product.small_image_uri,
                  name: product.name,
                  price: product.price,
                  short_description: product.short_description,
                  ...(product.text_data ? {long_description:
                    product.text_data.getEditorState().getCurrentContent().hasText() ?
                      product.text_data.toString('raw') : null
                  } : {}),
                  large_image_uri: product.large_image_uri,
                  ...(temp || !pristineCategories ? {categories: (product.categories ? product.categories.map(c => c.id) : [])} : {}),
                  ...(temp ? {project_id: project.id} : {}),
                };
                await saveProduct({ variables:{ input: inputValues}});

                for (const trad of product.i18n) {
                  if(trad.pristine !== undefined && !trad.pristine) {
                    await apolloClient.mutate({
                      mutation: queries.products.updateI18n,
                      variables: {input: {
                        product_id: product.id,
                        language_code: trad.language_code,
                        ...(trad.small_image_uri? {small_image_uri: trad.small_image_uri} : {}),
                        name: trad.name,
                        price: trad.price,
                        short_description: trad.short_description,
                        ...(trad.text_data ? {long_description:
                          trad.text_data.getEditorState().getCurrentContent().hasText() ?
                            trad.text_data.toString('raw') : null
                        } : {}),
                        ...(trad.large_image_uri? {large_image_uri: trad.large_image_uri} : {}),
                      }},
                      refetchQueries: () => [
                        {
                          query: queries.products.listForProjectId,
                          variables: {
                            project_id: project.id,
                            category_ids: categoriesFilter.map(c => c.id),
                          }
                        },
                        { query: queries.projects.getWithChildren, variables: {id: project.id} }
                      ]
                    })
                  }
                }

                if(!temp) this.setState({pristine: true, pristineCategories: true, editMode: false});
                if(onSave) onSave();
              }}>
                <FormattedMessage id={'product.save'} />
              </button>
            )}
          </Mutation>
          }
          {!canSave &&
            <button type={'button'} className={'tsk-button'} onClick={async () => {
              this.setState({editMode: false});
            }}>
              <FormattedMessage id={'product.close'} />
            </button>
          }
          {!temp &&
            <>
              <span
                className={"tsk-link-icon"}
                onClick={async () => {
                  const periods = await periodsPickerDialog({
                    labelId: 'product.hiddenrange.dialog.label',
                    periods: (product.visibility_periods || []).map(period => moment.range(period.start_date, period.end_date)),
                  });
                  await apolloClient.mutate({
                    mutation: queries.products.setVisibilityPeriodsForProduct,
                    variables: {
                      productId: product.id,
                      input: periods.map(period => ({
                        visibility: 'hidden',
                        start_date: period.start.toDate(),
                        end_date: period.end.toDate(),
                      }))
                    },
                    refetchQueries: () => [
                      {
                        query: queries.products.listForProjectId,
                        variables: {
                          project_id: project.id,
                          category_ids: categoriesFilter.map(c => c.id),
                        }
                      },
                      { query: queries.projects.getWithChildren, variables: {id: project.id} }
                    ]
                  });
                }}>
                <FontAwesomeIcon icon={'eye-slash'}/>
              </span>
              &nbsp;
              <span
                className={"tsk-link-icon"}
                onClick={async () => {
                  confirmDialog(this.deleteDialogConfirmationContent).then(res =>
                    apolloClient.mutate({
                      mutation: queries.products.del,
                      variables: {
                        input: product.id,
                      },
                      refetchQueries: () => [
                        {
                          query: queries.products.listForProjectId,
                          variables: {
                            project_id: project.id,
                            category_ids: categoriesFilter.map(c => c.id),
                          }
                        },
                      ]
                    })
                  )
                }}>
                <FontAwesomeIcon icon={'trash-alt'}/>
              </span>
            </>
          }
        </div>
      </div>
    )
  }
}

export default ProductEdition;
