import React, {useState} from "react";
import {Mutation, Query} from "react-apollo";
import queries from "../../../queries";
import _ from "lodash";
import {FormattedMessage} from "react-intl";
import FileUploader from "../../uploaders/FileUploader";

class AgendaCellEditor extends React.Component  {

  state = {
    i18n: {},
    background_color : this.props.cell.background_color,
    background_color_filter_category: this.props.cell.background_color_filter_category,
    background_color_filter_category_checked: this.props.cell.background_color_filter_category_checked,
    background_color_filter_day: this.props.cell.background_color_filter_day,
    background_color_filter_day_checked : this.props.cell.background_color_filter_day_checked,
    background_color_button_open : this.props.cell.background_color_button_open,
    background_color_pop_up : this.props.cell.background_color_pop_up,
    text_color : this.props.cell.text_color,
    text_color_day_filter: this.props.cell.text_color_day_filter,
    text_color_filter_category : this.props.cell.text_color_filter_category,
    text_color_time : this.props.cell.text_color_time,
    text_color_button_open : this.props.cell.text_color_button_open,
    text_color_event_hours : this.props.cell.text_color_event_hours,
    text_color_event_name: this.props.cell.text_color_event_name,
    text_color_event_details: this.props.cell.text_color_event_details,
    color_button_close : this.props.cell.color_button_close,
    font_size_name : this.props.cell.font_size_name,
    font_size_details : this.props.cell.font_size_details,
    font_size_details_pup_up : this.props.cell.font_size_details_pup_up,
    font_size_description_pop_up : this.props.cell.font_size_description_pop_up,
    bolder_radius_filter_day: this.props.cell.bolder_radius_filter_day,
    bolder_radius_filter__category: this.props.cell.bolder_radius_filter__category,
    font_size_filter_day: this.props.cell.font_size_filter_day,
    font_size_filter_category: this.props.cell.font_size_filter_category,
    border_color_selected_day: this.props.cell.border_color_selected_day,
    border_color_selected_category: this.props.cell.border_color_selected_category,
    text_color_category_filter: this.props.cell.text_color_category_filter,
    text_color_description: this.props.cell.text_color_description,
    text_color_category_filter_selected: this.props.cell.text_color_category_filter_selected,
    text_color_day_filter_selected: this.props.cell.text_color_day_filter_selected,
    isChange: false,
  };

  setI18nState(language, oldState, newState) {
    this.setState({i18n: {...this.state.i18n, [language.code]: {...oldState, ...newState}}});
  }

  render() {
    const { cell, language } = this.props;

    console.log({cell});

    return (
      <Query query={queries.cells.agenda.get} variables={{ id: cell.id }}>
        {({ loading, error, data }) => {
         console.log("Loading:", loading);
         console.log("Error:", error);
         console.log("Data:", data);
   
         if (loading) return null;
         if (error) return `Error!: ${error}`;
   
         const { agendaCell } = data;
         console.log("AgendaCell:", agendaCell);
   
         if (!agendaCell) return null;

          if(!!language) {
            return (
              <Mutation
                mutation={queries.agenda_category.updateI18n}
                refetchQueries={() => [{ query: queries.cells.agenda.get, variables: {id: cell.id} }]}
              >
                {(agenda) => {
                  return(
                  agendaCell.categories.map((category)=>{
                    return(
                      <UpdateI18nCategory language={language} key={category.id} category={category} update={agenda}/>
                    )
                  })
                )}}
              </Mutation>
            )
          }
          return (
            <Mutation
              mutation={queries.cells.agenda.update}
              refetchQueries={() => [{ query: queries.cells.agenda.get, variables: {id: cell.id} }]}
            >
              {(mutationFct) => (
               <div>
                <label>
                  <FormattedMessage id={"plan.edit.label.csv"}></FormattedMessage>
                </label>
                <FileUploader cellId={cell.id} accept={'.csv'} uploadPath='/csv_agenda/'>
                </FileUploader>
                <br/> <br/>
                <label>
                  Background du module
                </label>
                <input type="color" value={this.state.background_color} onChange={(e)=>{this.setState({background_color: e.target.value, isChange: true})}} />
                <br/> <br/>
                <label>
                  background du filtre par catégories
                </label>
                <input type="color" value={this.state.background_color_filter_category} onChange={(e)=>{this.setState({background_color_filter_category: e.target.value, isChange: true})}}/>
                <br/> <br/>
                <label>
                    background du filtre par catégories (coché)
                </label>
                <input type="color" value={this.state.background_color_filter_category_checked} onChange={(e)=>{this.setState({background_color_filter_category_checked: e.target.value, isChange: true})}}/>
                <br/> <br/>
                <label>
                    background du filtre par jour
                </label>
                <input type="color" value={this.state.background_color_filter_day} onChange={(e)=>{this.setState({background_color_filter_day: e.target.value, isChange: true})}}/>
                <br/> <br/>
                <label>
                  background du filtre par jour (coché)
                </label>
                <input type="color" value={this.state.background_color_filter_day_checked} onChange={(e)=>{this.setState({background_color_filter_day_checked: e.target.value, isChange: true})}}/>
                <br/> <br/>
                <label>
                  background du bouton ouvrir
                </label>
                <input type="color" value={this.state.background_color_button_open} onChange={(e)=>{this.setState({background_color_button_open: e.target.value, isChange: true})}}/>
                <br/> <br/>
                <label>
                    background de la pop up
                </label>
                <input type="color" value={this.state.background_color_pop_up} onChange={(e)=>{this.setState({background_color_pop_up: e.target.value, isChange: true})}}/>
                <br/> <br/>
                <label>
                  couleur de l'heure
                </label>
                <input type="color" value={this.state.text_color_time} onChange={(e)=>{this.setState({text_color_time: e.target.value, isChange: true})}}/>
                <br/> <br/>
                <label>
                    Couleur du text du bouton d'ouverture du popup
                </label>
                <input type="color" value={this.state.text_color_button_open} onChange={(e)=>{this.setState({text_color_button_open: e.target.value, isChange: true})}}/>
                <br/> <br/>
                <label>
                  Couleur de l'heure de l'évènement
                </label>
                <input type="color" value={this.state.text_color_event_hours} onChange={(e)=>{this.setState({text_color_event_hours: e.target.value, isChange: true})}}/>
                <br/> <br/>
                <label>
                  Couleur du nom de l'évènement
                </label>
                <input type="color" value={this.state.text_color_event_name} onChange={(e)=>{this.setState({text_color_event_name: e.target.value, isChange: true})}}/>
                <label>
                  Couleur des détails de l'évènement
                </label>
                <br/><br/>
                <input type="color" value={this.state.text_color_event_details} onChange={(e)=>{this.setState({text_color_event_details: e.target.value, isChange: true})}}/>
                <br/> <br/>
                <label>
                    Couleur du bouton de fermeture du popup
                </label>
                <input type="color" value={this.state.color_button_close} onChange={(e)=>{this.setState({color_button_close: e.target.value, isChange: true})}}/>
                <br/> <br/>
                <label>
                    Couleur du texte des categories
                </label>
                <input type="color" value={this.state.text_color_category_filter} onChange={(e)=>{this.setState({text_color_category_filter: e.target.value, isChange: true})}}/>
                <br/> <br/>
                <label>
                    Couleur du texte des jours
                </label>
                <input type="color" value={this.state.text_color_day_filter} onChange={(e)=>{this.setState({text_color_day_filter: e.target.value, isChange: true})}}/>
                <br/> <br/>
                <label>
                    Couleur du texte de la catégorie sélectionnée
                </label>
                <input type="color" value={this.state.text_color_category_filter_selected} onChange={(e)=>{this.setState({text_color_category_filter_selected: e.target.value, isChange: true})}}/>
                <br/> <br/>
                <label>
                    Couleur du texte du jours sélectionné
                </label>
                <input type="color" value={this.state.text_color_day_filter_selected} onChange={(e)=>{this.setState({text_color_day_filter_selected: e.target.value, isChange: true})}}/>
                <br/> <br/>
                <label>
                    Couleur des bordures du filtre par catégories
                </label>
                <input type="color" value={this.state.border_color_selected_category} onChange={(e)=>{this.setState({border_color_selected_category: e.target.value, isChange: true})}}/>
                <br/> <br/>
                <label>
                    Couleur des bordures du filtre par jours
                </label>
                <input type="color" value={this.state.border_color_selected_day} onChange={(e)=>{this.setState({border_color_selected_day: e.target.value, isChange: true})}}/>
                <br/> <br/>
                <input type="color" value={this.state.text_color_description} onChange={(e)=>{this.setState({text_color_description: e.target.value, isChange: true})}}/>
                <br/> <br/>
                <label>
                  Taille du nom de l'évènement
                </label>
                <input type="number" value={this.state.font_size_name} onChange={(e)=>{this.setState({font_size_name: e.target.value, isChange: true})}}/>
                <br/><br/>
                <label>
                  Taille du  détail de l'évènement
                </label>
                <input type="number" value={this.state.font_size_details} onChange={(e)=>{this.setState({font_size_details: e.target.value, isChange: true})}}/>
                <br/> <br/>
                <label>
                  Taille du détail de l'évènement (popup)
                </label>
                <input type="number" value={this.state.font_size_details_pup_up} onChange={(e)=>{this.setState({font_size_details_pup_up: e.target.value, isChange: true})}}/>
                <br/><br/>
                <label>
                  Taille du filtre par catégorie
                </label>
                <input type="number" value={this.state.font_size_filter_category} onChange={(e)=>{this.setState({font_size_filter_category: e.target.value, isChange: true})}}/>
                <br/><br/>
                <label>
                  Taille du filtre par jours
                </label>
                <input type="number" value={this.state.font_size_filter_day} onChange={(e)=>{this.setState({font_size_filter_day: e.target.value, isChange: true})}}/>
                <br/> <br/>
                <label>
                  bordure du filtre jour arrondi
                </label>
                <input type="checkbox" checked={this.state.bolder_radius_filter_day} value={this.state.bolder_radius_filter_day} onChange={(e)=>{this.setState({bolder_radius_filter_day: e.target.checked, isChange: true})}}/>
                <br/> <br/>
                <label>
                  bordure du filtre catégorie arrondi
                </label>
                <input type="checkbox" checked={this.state.bolder_radius_filter__category} value={this.state.bolder_radius_filter__category} onChange={(e)=>{this.setState({bolder_radius_filter__category: e.target.checked, isChange: true})}}/>
                <br/> <br/>
                <br/> <br/>
                <button className="tsk-button" onClick={()=>{mutationFct({variables: {input:{
                  id: cell.id,
                  background_color: this.state.background_color,
                  background_color_filter_category: this.state.background_color_filter_category,
                  background_color_filter_category_checked: this.state.background_color_filter_category_checked,
                  background_color_filter_day: this.state.background_color_filter_day,
                  background_color_filter_day_checked: this.state.background_color_filter_day_checked,
                  background_color_button_open: this.state.background_color_button_open,
                  background_color_pop_up: this.state.background_color_pop_up,
                  text_color: this.state.text_color,
                  text_color_day_filter: this.state.text_color_day_filter,
                  text_color_time: this.state.text_color_time,
                  text_color_button_open: this.state.text_color_button_open,
                  text_color_event_hours: this.state.text_color_event_hours,
                  text_color_event_name: this.state.text_color_event_name,
                  text_color_event_details: this.state.text_color_event_details,
                  color_button_close: this.state.color_button_close,
                  font_size_name: parseInt(this.state.font_size_name),
                  font_size_details: parseInt(this.state.font_size_details),
                  font_size_details_pup_up: parseInt(this.state.font_size_details_pup_up),
                  font_size_description_pop_up: parseInt(this.state.font_size_description_pop_up),
                  bolder_radius_filter_day: this.state.bolder_radius_filter_day,
                  bolder_radius_filter__category: this.state.bolder_radius_filter__category,
                  text_color_category_filter: this.state.text_color_category_filter,
                  text_color_category_filter_selected: this.state.text_color_category_filter_selected,
                  text_color_day_filter_selected: this.state.text_color_day_filter_selected,
                  border_color_selected_day: this.state.border_color_selected_day,
                  border_color_selected_category: this.state.border_color_selected_category,
                }}})}}>
                  <FormattedMessage id={'common.button.submit'}/>
                </button>
                </div>
              )}
            </Mutation>
          )
        }}
      </Query>
    );
  }
}

function UpdateI18nCategory({category, update, language}){

  const i18nValues = _.filter(category.i18n, {language_code: language.code})[0] || {};

  const [name, setName] = useState(i18nValues.name);

  return(
    <div index={category.id}>
      <label>
        {category.name}
      </label>
      <input type="text" className="input" value={name} onChange={(e)=>{setName(e.target.value)}}/>
      <button onClick={()=>{update({variables: {input: {name: name, category_id: category.id, language_code: language.code}}})}} className="tsk-button">
        Update
      </button>
    </div>
  )
}

export default AgendaCellEditor;
