import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import {ButtonDiffuseCell} from "@treeosk/common"
import queries from "../../../queries";
import SpecificCellPreview from "./SpecificCellPreview";

class ButtonDiffuseCellPreview extends React.Component {
    
    render() {
        const { cell } = this.props;
        const defaultRender = () => <FontAwesomeIcon icon={'external-link-alt'} />;
        
        return (
            <SpecificCellPreview
            cell={cell}
            defaultRender={defaultRender}
            query={queries.cells.button_diffuse.get}
            renderingCondition={(buttonDiffuseCell) => (!!buttonDiffuseCell.video_waiting_uri && !!buttonDiffuseCell.video_start_uri.length)}
            >
            {(buttonDiffuseCell) => <ButtonDiffuseCell preview={true} cell={buttonDiffuseCell} /> }
            </SpecificCellPreview>
        );
    }
    
}

export default ButtonDiffuseCellPreview;
