import React from "react";
import ImageCellPreview from "./ImageCellPreview";
import TextCellPreview from "./TextCellPreview";
import VideoCellPreview from "./VideoCellPreview";
import TabMenuCellPreview from "./TabMenuCellPreview";
import CarouselCellPreview from "./CarouselCellPreview";
import ProductsListCellPreview from "./ProductsListCellPreview";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ButtonCellPreview from "./ButtonCellPreview";
import PhotoCellPreview from "./PhotoCellPreview";

import '../../../style/Carousel.scss';
import EmailFormCellPreview from "./EmailFormCellPreview";
import FormCellPreview from "./FormCellPreview";
import IframeCellPreview from "./IframeCellPreview";
import WheelCellPreview from "./WheelCellPreview";
import PlanCellPreview from "./PlanCellPreview";
import QuizzCellPreview from "./QuizzCellPreview";
import RankingCellPreview from "./RankingCellPreview";
import ButtonDiffuseCellPreview from "./ButtonDiffuseCellPreview";
import AgendaCell from "./AgendaCellPreview";

class CellPreview extends React.Component  {

  render() {
    const { cell, screenType } = this.props;

    if (!cell) return null;

    switch (cell.cell_type) {
      case "image":
        return <ImageCellPreview cell={cell} />;
      case "text":
        return <TextCellPreview cell={cell} />;
      case "video":
        return <VideoCellPreview cell={cell} />;
      case "button":
        return <ButtonCellPreview cell={cell} />;
      case "separator":
        return <div className={"tsk-placeholder-cell-preview"}><FontAwesomeIcon icon={'arrows-alt-v'} /></div>;
      case "language_selection":
        return <div className={"tsk-placeholder-cell-preview"}><FontAwesomeIcon icon={'language'} /></div>;
      case "form":
        return <FormCellPreview cell={cell} screenType={screenType} />;
      case "email_form":
        return <EmailFormCellPreview cell={cell} screenType={screenType} />;
      case "tab_menu":
        return <TabMenuCellPreview cell={cell} />;
      case "carousel":
        return <CarouselCellPreview cell={cell} screenType={screenType} />;
      case "products_list":
        return <ProductsListCellPreview cell={cell} />;
      case "memory_game":
        return <div className={"tsk-placeholder-cell-preview"}><FontAwesomeIcon icon={'gamepad'} /></div>;
      case "iframe":
        return <IframeCellPreview cell={cell}/>;
      case"wheel":
        return <WheelCellPreview cell={cell}/>;
      case"plan":
        return <PlanCellPreview cell={cell} screenType={screenType} />;
      case"quizz":
        return <QuizzCellPreview cell={cell} screenType={screenType}/>
      case"ranking":
        return <RankingCellPreview cell={cell} screenType={screenType}/>
      case"button_diffuse":
        console.log({cell});
        return <ButtonDiffuseCellPreview cell={cell} screenType={screenType}/>;
      case"agendas":
        return <AgendaCell cell={cell} screenType={screenType}/>
      case 'photo':
        return <PhotoCellPreview cell={cell} screenType={screenType}/>;
      default:
        return <div className={"tsk-placeholder-cell-preview"}><span>{cell.cell_type}</span></div>;
    }
  }
}

export default CellPreview;
