import React, {Fragment} from "react";
import {Mutation, Query} from "react-apollo";
import queries from "../../../queries";
import {withRouter} from "react-router-dom";
import {FormattedMessage, injectIntl} from "react-intl";
import NeedAdmin from "../../NeedAdmin";

class CommonCellEditor extends React.Component  {

  state = {};

  render() {
    const { language } = this.state;
    const { cell, children, intl, screenType } = this.props;
    const { projectId, pageId } = this.props.match.params;

    if (!cell) return null;

    const canLink = (() => {
      switch (cell.cell_type) {
        case "separator":
        case "language_selection":
        case "email_form":
        case "coca_form":
        case "form":
        case "tab_menu":
        case "carousel":
        case "iframe":
        case "wheel":
        case "plan":
        case "quizz":
        case "button_diffuse":
        case "agenda":
        case "photo":
          return false;
        default:
          return true
      }
    })();

    return (
      <Fragment>
        <Mutation
          mutation={queries.cells.updateCells}
          refetchQueries={() => [{ query: queries.pages.getWithChildren, variables: {id: pageId, screen_type_id: screenType.id} }]}
        >
          {(updateCells) => (
            <div>
              <Query query={queries.projects.getWithChildren} variables={{ id: projectId }}>
                {({ loading, error, data }) => {
                  if (loading) return null;
                  if (error) return `Error!: ${error}`;

                  const {project} = data;
                  const linkToNoneTxt = intl.formatMessage({id: 'cell.links.selector.none'});
                  const linkToBackTxt = intl.formatMessage({id: 'cell.links.selector.back'});
                  const keepLanguageLinkTxt = intl.formatMessage({id: 'cell.links.language.keep'});

                  const selectValue = cell.is_back_link ? 'back' : (cell.links_to_page_id ? cell.links_to_page_id : '');

                  return (
                    <Fragment>
                      {(!!project.languages && project.languages.length > 1) &&
                      <div className={'tsk-input-line'}>
                        <button
                          onClick={() => this.setState({language: undefined})}
                          className={`${!language ? 'current' : ''} tsk-button`}
                        >
                          <FormattedMessage id={'i18n.language.general'} />
                        </button>
                        {project.languages.map((aLanguage) => (
                          <button
                            key={aLanguage.code}
                            onClick={() => this.setState({language: aLanguage})}
                            className={`${(!!language && aLanguage.code === language.code) ? 'current' : ''} tsk-button`}
                          >
                            <FormattedMessage id={`i18n.language.name.${aLanguage.code}`} defaultMessage={aLanguage.name} />
                          </button>
                        ))}
                      </div>
                      }
                      <NeedAdmin>
                      {canLink && !language &&
                      <div className={'tsk-input-line'}>
                        <label htmlFor={'cell-links-to'}>
                          <FormattedMessage id={'cell.links.selector.label'} />
                        </label>
                        <select name={'cell-links-to'} value={ selectValue } onChange={(e) => {
                          const newLink = e.target.value === '' ? null : e.target.value;
                          if (newLink === 'back') {
                            updateCells({ variables:{ input: [{id: cell.id, is_back_link: true}]}});
                          } else if(newLink !== cell.links_to_page_id) {
                            updateCells({ variables:{ input: [{id: cell.id, is_back_link: false, links_to_page_id: newLink}]}});
                          }
                        }}>
                          <option value={''}>{linkToNoneTxt}</option>
                          <option value={'back'}>{linkToBackTxt}</option>
                          {project.pages.map((page) => {
                            if(page.id === pageId) return null;
                            return <option key={page.id} value={page.id}>{page.name}</option>;
                          })}
                        </select>
                        {!!cell.links_to_page_id &&
                          <select name={'cell-links-language'} value={ cell.links_to_language || ''} onChange={(e) => {
                            const newLanguage = e.target.value === '' ? null : e.target.value;
                            updateCells({ variables:{ input: [{id: cell.id, links_to_language: newLanguage}]}});
                          }}>
                            <option value={''}>{keepLanguageLinkTxt}</option>
                            {project.languages.map((language) => (
                              <option key={language.code} value={language.code}>{language.name}</option>
                            ))}
                          </select>
                        }
                      </div>
                      }
                      </NeedAdmin>
                    </Fragment>
                  )
                }}
              </Query>
            </div>
          )}
        </Mutation>
        {children(language)}
      </Fragment>
    )
  }
}

export default injectIntl(withRouter(CommonCellEditor));
