import React, {useState, useEffect} from 'react'
import {useParams} from 'react-router-dom';
import {backEndUri} from '../../config';

export default function PhotoView() {

    const {photoDatasId} = useParams();

    const [photoDatas, setPhotoDatas] = useState(null);

    const loadPhotoDatas = async () => {
      fetch(`${backEndUri}/photo?id=${photoDatasId}`)
        .then(response => response.json())
        .then(data => setPhotoDatas(data))
        .catch(error => console.error(error));
    }

    useEffect(()=>{
        loadPhotoDatas();
    }, [photoDatasId]);

    if(!photoDatas){
        return <Loading isLoading={true} />
    }

    return (
        <Image photoDatas={photoDatas} />
    );
}

function Loading({isLoading}) {
    return (
        <div
                style={{
                    width: '100vw',
                    height: '100vh',
                    background: 'black',
                    position: 'fixed',
                    margin: 0,
                    padding: 0,
                    top: 0,
                    left: 0,
                    color: 'white',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center',
                }}
            >
                {isLoading ?
                <div
                    style={{
                        border: '8px solid #f3f3f3', /* Light gray */
                        borderTop: '8px solid #3498db', /* Blue */
                        borderRadius: '50%',
                        width: '50px',
                        height: '50px',
                        animation: 'spin 1s linear infinite',
                    }}
                ></div>
                :
                <h1>
                    Photo not found
                </h1>
                }
    
                <style>
                    {`
                        @keyframes spin {
                            0% { transform: rotate(0deg); }
                            100% { transform: rotate(360deg); }
                        }
                    `}
                </style>
            </div>
    )
}

function Image({ photoDatas }) {
  const imageUrl = photoDatas.image_uri; // Lien vers l'image (vous pouvez changer cela)
  const imageAlt = photoDatas.id;

  const downloadImage = () => {
    const link = document.createElement('a');
    link.href = imageUrl;
    link.download = photoDatas.image_uri; // Nom de l'image lors du téléchargement
    link.click();
  };

  // Styles en ligne avec des améliorations
  const appStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    backgroundColor: '#000000',
    fontFamily: "'Roboto', sans-serif",
    color: '#333',
    textAlign: 'center',
    padding: '20px',
    overflow: 'scoll',
  };

  const imageContainerStyle = {
    marginBottom: '30px',
    borderRadius: '12px',
    overflow: 'hidden',
    boxShadow: '0 12px 24px rgba(0, 0, 0, 0.1)',
    transition: 'transform 0.3s ease, box-shadow 0.3s ease',
    transformOrigin: 'center',
    transform: 'scale(0.24)',
  };

  const imageStyle = {
    display: 'block',
    transition: 'transform 0.3s ease',
    objectFit: 'contain',
    width: '360px',
    height: '640px',
  };

  const buttonStyle = {
    padding: '15px 25px',
    fontSize: '18px',
    color: '#fff',
    backgroundColor: '#4CAF50',
    border: 'none',
    borderRadius: '30px',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease, transform 0.2s ease',
    boxShadow: '0 4px 8px rgba(255, 255, 255, 0.2)',
  };

  const buttonHoverStyle = {
    backgroundColor: '#45a049',
    transform: 'scale(1.05)',
  };

  const [isHovered, setIsHovered] = useState(false);
  const [isImageHovered, setIsImageHovered] = useState(false);

  return (
    <div style={appStyle}>
      <div
        style={{
          ...imageContainerStyle,
          transform: isImageHovered ? 'scale(1.05)' : 'scale(1)',
          boxShadow: isImageHovered
            ? '0 16px 32px rgba(0, 0, 0, 0.2)'
            : '0 12px 24px rgba(0, 0, 0, 0.1)',
        }}
        onMouseEnter={() => setIsImageHovered(true)}
        onMouseLeave={() => setIsImageHovered(false)}
      >
        <img width={'1080'} height={'1920px'} src={imageUrl} alt={imageAlt} style={imageStyle} />
      </div>
      <button
        style={{
          ...buttonStyle,
          backgroundColor: isHovered ? buttonHoverStyle.backgroundColor : buttonStyle.backgroundColor,
          transform: isHovered ? buttonHoverStyle.transform : 'scale(1)',
        }}
        onClick={downloadImage}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        Télécharger l'image
      </button>
    </div>
  );
}