import React, { Component } from 'react';
import PropTypes from 'prop-types';

import '../../style/Main.scss';
import '../../style/Layout.scss';
import Sidebar from "./Sidebar";
import Topbar from "./Topbar";

class Layout extends Component {

  render() {
    const {identityProvider} = this.props;

    document.getElementsByTagName('html')[0].classList.add("tsk");

    return (
      <div className='tsk-layout'>
        <div id='tsk-sidebar'>
          <div id='tsk-main-logo'>
            <img alt='logo-treeosk' src='/logo.png' width={"100%"} height={"auto"} id='logo'/>
          </div>
          <Sidebar identityProvider={identityProvider}/>
        </div>
        <div id='tsk-main'>
          <Topbar identityProvider={identityProvider} />
          <div className='tsk-container'>
            {this.props.children}
          </div>
        </div>
      </div>
    );
  }
}

Layout.propTypes = {
  identityProvider: PropTypes.object.isRequired,
};

export default Layout;