import gql from "graphql-tag";

export const create = gql`
    mutation ($input: CreateCategoryInput!) {
    createCategory (input: $input) {
      id
      name
    }
  }`;

export const deleteCategory = gql`
  mutation ($input: ID!) {
  deleteCategory (input: $input) {
    id
  }
}`;
