import gql from "graphql-tag";

export default {
    update: gql`
    mutation UpdateAgendaCell ($input: UpdateAgendaCellInput!) {
        updateAgendaCell (input: $input) {
            id
            cell_type
            background_color
            background_color_filter_category
            background_color_filter_category_checked
            background_color_filter_day
            background_color_filter_day_checked
            background_color_button_open
            background_color_pop_up
            text_color
            text_color_day_filter
            text_color_time
            text_color_button_open
            text_color_event_hours
            text_color_event_name
            text_color_event_details
            color_button_close
            font_size_name
            font_size_details
            font_size_details_pup_up
            font_size_description_pop_up
            bolder_radius_filter_day
            bolder_radius_filter__category
            font_size_filter_day
            font_size_filter_category
            border_color_selected_day
            border_color_selected_category
            text_color_category_filter
            text_color_description
            text_color_day_filter_selected
            text_color_category_filter_selected
            days {
                id
                cell_id
                date
                text
                i18n {
                    language_code
                    text
                }
            }
            categories {
                id
                cell_id
                name
                i18n {
                    language_code
                    name
                }
            }
            events {
                id
                cell_id
                name
                agenda_day_id
                start
                end
                description
                image_uri
                speaker
                location
                language
                category_id
                i18n {
                    language_code
                    name
                    description
                    image_uri
                    speaker
                    location
                    language
                }
            }
            i18n {
                language_code
                text
            }
        }
    }`,

    get: gql`
    query AgendaCell($id: ID!) {
        agendaCell(id: $id) {
            id
            cell_type
            background_color
            background_color_filter_category
            background_color_filter_category_checked
            background_color_filter_day
            background_color_filter_day_checked
            background_color_button_open
            background_color_pop_up
            text_color
            text_color_day_filter
            text_color_time
            text_color_button_open
            text_color_event_hours
            text_color_event_name
            text_color_event_details
            color_button_close
            font_size_name
            font_size_details
            font_size_details_pup_up
            font_size_description_pop_up
            bolder_radius_filter_day
            bolder_radius_filter__category
            font_size_filter_day
            font_size_filter_category
            border_color_selected_day
            border_color_selected_category
            text_color_category_filter
            text_color_description
            text_color_day_filter_selected
            text_color_category_filter_selected
            days {
                id
                cell_id
                date
                text
                i18n {
                    language_code
                    text
                }
            }
            categories {
                id
                cell_id
                name
                i18n {
                    language_code
                    name
                }
            }
            events {
                id
                cell_id
                name
                agenda_day_id
                start
                end
                description
                image_uri
                speaker
                location
                language
                category_id
                i18n {
                    language_code
                    name
                    description
                    image_uri
                    speaker
                    location
                    language
                }
            }
            i18n {
                language_code
                text
            }
        }
    }`,

    updateAgendaDay: gql`
    mutation UpdateAgendaDay($input: UpdateAgendaDayInput!) {
        updateAgendaDay(input: $input) {
            id
            text
            date
            i18n {
                language_code
                text
            }
        }
    }`,
}