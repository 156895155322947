import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import {AgendaCell} from "@treeosk/common"
import queries from "../../../queries";
import SpecificCellPreview from "./SpecificCellPreview";

class AgendaCellPreview extends React.Component {
    
    render() {
        const { cell } = this.props;
        const defaultRender = () => <FontAwesomeIcon icon={'external-link-alt'} />;
        
        return (
            <SpecificCellPreview
            cell={cell}
            defaultRender={defaultRender}
            query={queries.cells.agenda.get}
            renderingCondition={(buttonDiffuseCell) => true}
            >
            {(buttonDiffuseCell) => <AgendaCell preview={true} cell={buttonDiffuseCell} /> }
            </SpecificCellPreview>
        );
    }
    
}

export default AgendaCellPreview;
