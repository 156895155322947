import _ from'lodash';
import React from "react";
import {Mutation} from "react-apollo";
import queries from "../../queries";
import {withRouter} from "react-router-dom";
import {FormattedMessage} from "react-intl";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class CellToolbox extends React.Component  {

  render() {
    const { screenType } = this.props;
    const { pageId } = this.props.match.params;

    const cellTypes = {
      image: {
        icon: ['far', 'image'],
      },
      text: {
        icon: 'paragraph',
      },
      video: {
        icon: 'film',
      },
      button: {
        icon: 'hand-pointer',
      },
      language_selection: {
        icon: 'language',
      },
      separator: {
        icon: 'arrows-alt-v',
      },
      carousel: {
        icon: ['far', 'images'],
      },
      form: {
        icon: 'clipboard-list',
      },
      tab_menu: {
        icon: 'ellipsis-h',
      },
      products_list: {
        icon: ['far', 'list-alt'],
      },
      memory_game: {
        icon: 'gamepad',
      },
      iframe: {
        icon: 'external-link-alt',
      },
      wheel:{
        icon: 'dharmachakra',
      },
      plan:{
        icon: ["fas", "map"],
      },
      quizz:{
        icon: 'question',
      },
      ranking:{
        icon: 'award'
      },
      button_diffuse: {
        icon: 'fa-solid fa-spray-can-sparkles'
      },
      agendas: {
        icon: 'fa-solid fa-spray-can-sparkles'
      },
      batibouw_finder: {
        icon: 'fa-solid fa-spray-can-sparkles'
      },
      photo: {
        icon: 'fa-solid fa-spray-can-sparkles'
      }
    };

    return (
      <Mutation
        mutation={queries.cells.create}
        refetchQueries={() => [{ query: queries.pages.getWithChildren, variables: {id: pageId, screen_type_id: screenType.id} }]}
      >
        {(createCell) => (
          _.map(cellTypes, (data, cellType) => (
            <button
              key={cellType}
              className={'tsk-toolbox-button tsk-button'}
              onClick={() => {
              const cellCreationInfo = {
                page_id: pageId,
                cell_type: cellType,
              };
              createCell({ variables: { input: cellCreationInfo  }});
            }}>
              <div className={'tsk-toolbox-icon'}>
                <FontAwesomeIcon icon={data.icon} />
              </div>
              <FormattedMessage id={`page.add.cell.button.${cellType}`} />
            </button>
          ))
        )}
      </Mutation>
    )
  }
}

export default withRouter(CellToolbox);
