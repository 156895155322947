import gql from "graphql-tag";

export default {
update: gql`
    mutation UpdatePhotoCell ($input: UpdatePhotoCellInput!) {
        updatePhotoCell (input: $input) {
            id
            frame_uri
            btn_uri
            i18n {
                language_code
                btn_uri
            }
        }
    }`,

get: gql`
    query PhotoCell($id: ID!) {
        photoCell(id: $id) {
            id
            frame_uri
            btn_uri
            i18n {
                language_code
                btn_uri
            }
        }
    }`
}
