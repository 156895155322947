import gql from "graphql-tag";

export default {
  update: gql`
    mutation UpdateI18nCell ($input: I18nFieldsInput!) {
      i18nCell (input: $input) {
        language_code
        text
        text_data
        image_uri
        video_uri
        button_background_image_uri
        background_image_uri
        background_video_uri
        name
      }
    }`,
}
