import React from "react";
import ImageUploader from "../../uploaders/ImageUploader";
import queries from "../../../queries";
import {Mutation, Query} from "react-apollo";

export default function PhotoCellEditor({cell, language}){

    if(language){
        return (
            <Query query={queries.cells.photo.get} variables={{ id: cell.id }}>
            {
                ({ loading, error, data }) => {
                    if(loading) return null;
                    if(error) return `Error!: ${error}`;
                    const {photoCell} = data;
                    return(
                        <div>
                            <label>
                                Photo du cadre
                            </label>
                            <Mutation refetchQueries={() => [{ query: queries.cells.photo.get, variables: {id: cell.id} }]} mutation={queries.cells.i18n.update}>
                                {
                                    (updatePhotoCell) => {
                                        return(
                                            <>
                                                <ImageUploader onUpload={
                                                    (imageUri) => {
                                                        updatePhotoCell({
                                                            variables: {
                                                                input: {
                                                                    cell_id: cell.id,
                                                                    language_code: language.code,
                                                                    btn_uri: imageUri,
                                                                }
                                                            }
                                                        });
                                                    }
                                                } value={photoCell.btn_uri}/>
                                            </>
                                        );
                                    }
                                }
                            </Mutation>
                            <button className="tsk-button">
                                Update
                            </button>
                        </div>
                    );
                }
            }
        </Query>
        )
    }
    
    return(
        <Query query={queries.cells.photo.get} variables={{ id: cell.id }}>
            {
                ({ loading, error, data }) => {
                    if(loading) return null;
                    if(error) return `Error!: ${error}`;
                    const {photoCell} = data;
                    return(
                        <div>
                            <label>
                                Photo du cadre
                            </label>
                            <Mutation refetchQueries={() => [{ query: queries.cells.photo.get, variables: {id: cell.id} }]} mutation={queries.cells.photo.update}>
                                {
                                    (updatePhotoCell) => {
                                        return(
                                            <>
                                                <ImageUploader onUpload={
                                                    (imageUri) => {
                                                        updatePhotoCell({
                                                            variables: {
                                                                input: {
                                                                    id: cell.id,
                                                                    frame_uri: imageUri
                                                                }
                                                            }
                                                        });
                                                    }
                                                } value={photoCell.frame_uri}/>
                                                <ImageUploader onUpload={
                                                    (imageUri) => {
                                                        updatePhotoCell({
                                                            variables: {
                                                                input: {
                                                                    id: cell.id,
                                                                    btn_uri: imageUri
                                                                }
                                                            }
                                                        });
                                                    }
                                                } value={photoCell.btn_uri}/>
                                            </>
                                        );
                                    }
                                }
                            </Mutation>
                            <button className="tsk-button">
                                Update
                            </button>
                        </div>
                    );
                }
            }
            
        </Query>
    );
}