import gql from "graphql-tag";

export const createMenu = gql`
    mutation ($input: CreateTabMenuInput!) {
    createTabMenu (input: $input) {
      id
      name
    }
  }`;

export const addItem = gql`
    mutation ($input: AddTabMenuItemInput!) {
    addTabMenuItem (input: $input) {
      id
      position
      text
    }
  }`;

export const updateItem = gql`
  mutation ($input: UpdateTabMenuItemInput!) {
    updateTabMenuItem (input: $input) {
      id
      position
      text
    }
  }`;

  export const deleteTabMenu = gql`
  mutation ($input: ID!) {
    deleteTabMenu (input: $input) {
      id
    }
  }
  `

export const deleteTabMenuItem = gql`
mutation ($input: ID!) {
  deleteTabMenuItem (input: $input) {
    id
  }
}
`;


export const update = gql`
  mutation ($input: UpdateTabMenuInput!) {
    updateTabMenu (input: $input) {
      id
      name
      background_type
      background_image_uri
      background_video_uri
      background_color
      text_size
      text_color
      font
      current_text_color
      current_font
      items {
        id
        position
        text
        to_page_id
        i18n {
          language_code
          text
        }
      }
    }
  }`;

export const get = gql`
  query TabMenu($id: ID!) {
    tabMenu(id: $id) {
      id
      name
      background_type
      background_image_uri
      background_video_uri
      background_color
      text_size
      text_color
      font
      current_text_color
      current_font
      items {
        id
        position
        text
        to_page_id
        i18n {
          language_code
          text
        }
      }
    }
  }`;

export const listForProjectId = gql`
  query TabMenusForProjectId($project_id: ID!) {
    tabMenusForProjectId(project_id: $project_id) {
      id
      name
      background_type
      background_image_uri
      background_video_uri
      background_color
      text_size
      text_color
      font
      current_text_color
      current_font
      items {
        id
        position
        text
        to_page_id
        i18n {
          language_code
          text
        }
      }
    }
  }`;

export const updateI18n = gql`
  mutation UpdateI18nTabItem ($input: TabMenuItemI18nInput!) {
    i18nTabMenuItem (input: $input) {
      language_code
      text
    }
  }`;
