import React from "react";
import {withRouter} from "react-router-dom";
import {Mutation, Query} from "react-apollo";
import {FormattedMessage} from "react-intl";
import queries from "../../queries";
import ProductEdition from "./ProductEdition";
import Select from "react-select";
import {utils} from "@treeosk/common";

class ProductsList extends React.Component {
  state = {
    categoriesFilter: [],
  };

  render() {
    const { projectId } = this.props.match.params;
    const { tmpProduct, language, categoriesFilter, categoriesDelete } = this.state;

    return (
      <Query query={queries.projects.getWithChildren} variables={{ id: projectId }}>
        {({ loading, error, data }) => {
          if (loading) return null;
          if (error) return `Error!: ${error}`;

          const { project } = data;
          return (
            <div className={'tsk-product-lists-editor-container'}>
              <Mutation refetchQueries={[{query:queries.products.getWithChildren, variables:{ id: projectId }}]} mutation={queries.categories.deleteCategory}>
                {
                  (deleteCategory) => (
                    <>
                      <div className={'tsk-input-line'}>
                        <label><FormattedMessage id={'products.category.filter.label'}/></label>
                        <Select
                          className={'tsk-long-select'}
                          isClearable={true}
                          isMulti={true}
                          getOptionLabel={(category) => category.name}
                          getOptionValue={(category) => category.id}
                          value={categoriesFilter}
                          onChange={(categoriesFilter) => this.setState({categoriesFilter})}
                          options={project.categories}
                        />
                      </div>
                      <div className={'tsk-input-line'}>
                        <label>Delete Category</label>
                        <select onChange={(e)=>{this.setState({...this.state, categoriesDelete: e.target.value})}} value={categoriesDelete} className="tsk-select">
                          <option value={null}>
                            Category to delete
                          </option>
                          {project.categories.map((category) => (
                            <option key={category.id} value={category.id}>
                              {category.name}
                            </option>
                          ))}
                        </select>
                        <button className="tsk-button" onClick={() => {categoriesDelete && deleteCategory({variables: {input: categoriesDelete}})}}>
                          Supprimer
                        </button>
                      </div>
                  </>
                  )
                }
              </Mutation>
              {(!!project.languages && project.languages.length > 1) &&
              <div className={'tsk-input-line'}>
                <button
                  onClick={() => this.setState({language: undefined})}
                  className={`${!language ? 'current' : ''} tsk-button`}
                >
                  <FormattedMessage id={'i18n.language.general'} />
                </button>
                {project.languages.map((aLanguage) => (
                  <button
                    key={aLanguage.code}
                    onClick={() => this.setState({language: aLanguage})}
                    className={`${(!!language && aLanguage.code === language.code) ? 'current' : ''} tsk-button`}
                  >
                    <FormattedMessage id={`i18n.language.name.${aLanguage.code}`} defaultMessage={aLanguage.name} />
                  </button>
                ))}
              </div>
              }
              <Query
                query={queries.products.listForProjectId}
                variables={{
                  project_id: projectId,
                  category_ids: categoriesFilter.map(c => c.id),
                }}
              >
                {({ loading, error, data }) => {
                  if (loading) return null;
                  if (error) return `Error! ${error.message}`;
                  if (!data.productsForProjectId) return null;

                  return (
                    <ul className='tsk-product-list-container'>
                      {!tmpProduct &&
                      <button className={"tsk-button tsk-product-new-button"} onClick={() => this.setState({tmpProduct: {categories: [], i18n: []}})}>
                        <FormattedMessage id={'product.new'}/>
                      </button>
                      }
                      {tmpProduct &&
                      <li className='tsk-product-list-item'>
                        <ProductEdition categoriesFilter={categoriesFilter}
                                        product={tmpProduct}
                                        temp={true}
                                        language={language}
                                        project={project} onSave={() =>
                                          this.setState({tmpProduct: undefined})
                                        }
                        />
                      </li>
                      }
                      {data.productsForProjectId.map((product) => {
                        const containerClasses = `tsk-product-list-item ${utils.isProductVisible(product)? '' : 'tsk-product-list-item--is-hidden'}`;

                        return (
                          <li key={product.id} className={containerClasses}>
                            <ProductEdition categoriesFilter={categoriesFilter}
                                            product={product}
                                            temp={false}
                                            project={project}
                                            language={language} />
                          </li>
                        )
                      })}
                    </ul>
                  )
                }}
              </Query>
            </div>
          )
        }}
      </Query>
    );
  }
}

export default withRouter(ProductsList);
