import React, {Fragment} from 'react';

import {Mutation, Query} from "react-apollo";
import {stateOrValue} from "../../../utils";
import queries from "../../../queries";
import {FormattedMessage, injectIntl} from "react-intl";
import ImageUploader from "../../uploaders/ImageUploader";
import PaletteColorPicker from "../../PaletteColorPicker";
import {withRouter} from "react-router-dom";
import VideoUploader from "../../uploaders/VideoUploader";
import _ from "lodash";
import GlobalButtonSettings from "./GlobalButtonSettings";

class ButtonCellEditor extends React.Component {

  state = {
    i18n: {}
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!_.isEqual(prevProps.cell, this.props.cell)) {
      const emptyState = _.mapValues(this.state, () => undefined);
      this.setState({...emptyState, i18n: {}});
    }
  }

  setI18nState(language, oldState, newState) {
    this.setState({i18n: {...this.state.i18n, [language.code]: {...oldState, ...newState}}});
  }

  render() {
    const { projectId } = this.props.match.params;
    const {cell, intl, language} = this.props;

    if (!cell) return null;

    return (
      <Query query={queries.cells.button.get} variables={{ id: cell.id }}>
        {({ loading, error, data }) => {
          if (loading) return null;
          if (error) return `Error!: ${error}`;

          const {buttonCell} = data;
          if (!buttonCell) {
            console.error('Button cell not found');
            return null
          };

          if(!!language) {
            const i18nState = this.state.i18n[language.code] || {};
            const i18nValues = _.filter(buttonCell.i18n, {language_code: language.code})[0] || {};
            return (
              <Mutation
                mutation={queries.cells.i18n.update}
                refetchQueries={() => [{ query: queries.cells.button.get, variables: {id: cell.id} }]}
              >
                {(i18nCell) => (
                  <div>
                    <div className={'tsk-input-line'}>
                      <label htmlFor={'text'}><FormattedMessage id={'cell.button.text.label'} /></label>
                      <input name={'text'} value={stateOrValue(i18nState.text, i18nValues.text, {defaultValue: ''})} onChange={(e) => {
                        this.setI18nState(language, i18nState, {text: e.target.value})
                      }}/>
                    </div>

                    {/*"button_background_image_uri": null,*/}

                    {stateOrValue(this.state.backgroundType, buttonCell.background_type, {transform: (val) => val === 'image'}) &&
                    <Fragment>
                      <label>
                        <FormattedMessage id={'cell.button.cellbackground.section.label'} />
                        &nbsp;
                        <FormattedMessage id={'cell.text.background.image.label'} />
                      </label>
                      <div className={'tsk-input-line'}>
                        <ImageUploader
                          value={stateOrValue(i18nState.background_image_uri, i18nValues.background_image_uri)}
                          onUpload={(imageUri) => {
                            this.setI18nState(language, i18nState, {background_image_uri: imageUri})
                          }}
                        />
                      </div>
                    </Fragment>
                    }

                    {stateOrValue(this.state.backgroundType, buttonCell.background_type, {transform: (val) => val === 'video'}) &&
                    <Fragment>
                      <label>
                        <FormattedMessage id={'cell.button.cellbackground.section.label'} />
                        &nbsp;
                        <FormattedMessage id={'cell.text.background.video.label'} />
                      </label>
                      <div className={'tsk-input-line'}>
                        <VideoUploader
                          value={stateOrValue(i18nState.background_video_uri, i18nValues.background_video_uri)}
                          onUpload={(videoUri) => {
                            this.setI18nState(language, i18nState, {background_video_uri: videoUri})
                          }}
                        />
                      </div>
                    </Fragment>
                    }

                    <div className={'tsk-bottom-buttons'}>
                      <button onClick={() => {

                        const data = {
                          cell_id: cell.id,
                          language_code: language.code,
                          ...(i18nState.text !== undefined ? {text: i18nState.text} : {}),
                          ...(i18nState.background_image_uri !== undefined ? {background_image_uri: i18nState.background_image_uri} : {}),
                          ...(i18nState.background_video_uri !== undefined ? {background_video_uri: i18nState.background_video_uri} : {}),
                        };
                        i18nCell({ variables: { input: data }})
                      }}>
                        <FormattedMessage id={'common.button.submit'} />
                      </button>
                    </div>
                  </div>
                )}
              </Mutation>
            )
          }
          return (
            <Mutation
              mutation={queries.cells.button.update}
              refetchQueries={() => [{ query: queries.cells.button.get, variables: {id: cell.id} }]}
            >
              {(updateButtonCell) => (
                <div>
                  <div className={'tsk-input-line'}>
                    <label htmlFor={'text'}><FormattedMessage id={'cell.button.text.label'} /></label>
                    <input name={'text'} value={stateOrValue(this.state.text, buttonCell.text)} onChange={(e) => {
                      this.setState({text: e.target.value});
                    }}/>
                  </div>
                  <div className={'tsk-input-line'}>
                    <label htmlFor={'horizontalAlign'}><FormattedMessage id={'cell.text.horizontalalign.label'} /></label>
                    <select
                      value={stateOrValue(this.state.horizontalAlign, buttonCell.horizontal_align, {defaultValue: ''})}
                      onChange={(e) => {
                        this.setState({horizontalAlign : e.target.value});
                      }}
                    >
                      <option value={'left'}>{intl.formatMessage({id: 'cell.text.horizontalalign.left'})}</option>
                      <option value={'center'}>{intl.formatMessage({id: 'cell.text.horizontalalign.center'})}</option>
                      <option value={'right'}>{intl.formatMessage({id: 'cell.text.horizontalalign.right'})}</option>
                    </select>
                  </div>

                  <div className={'tsk-input-line'}>
                    <label htmlFor={'verticalAlign'}><FormattedMessage id={'cell.text.verticalalign.label'} /></label>
                    <select
                      value={stateOrValue(this.state.verticalAlign, buttonCell.vertical_align, {defaultValue: ''})}
                      onChange={(e) => {
                        this.setState({verticalAlign : e.target.value});
                      }}
                    >
                      <option value={'top'}>{intl.formatMessage({id: 'cell.text.verticalalign.top'})}</option>
                      <option value={'middle'}>{intl.formatMessage({id: 'cell.text.verticalalign.middle'})}</option>
                      <option value={'bottom'}>{intl.formatMessage({id: 'cell.text.verticalalign.bottom'})}</option>
                    </select>
                  </div>

                  <Query query={queries.projects.getWithChildren} variables={{ id: projectId }}>
                    {(projectQueryResponse) => {
                      if (projectQueryResponse.loading) return null;
                      if (projectQueryResponse.error) return `Error!: ${projectQueryResponse.error}`;
                      const {project} = projectQueryResponse.data;
                      if(!project) return null;

                      return (
                        <GlobalButtonSettings fonts={project.fonts} palette={project.color_palette} currentValue={buttonCell} onChange={(newState) => this.setState(newState)} />
                      )
                    }}
                  </Query>

                  <div className={'tsk-setting-section'}>
                    <label><FormattedMessage id={'cell.button.clickcolors.section.label'} /></label>
                    <ul>
                      <li>
                        <div className={'tsk-input-line'}>
                          <label><FormattedMessage id={'cell.button.clickcolors.background.label'}/></label>
                          <Query query={queries.projects.getWithChildren} variables={{ id: projectId }}>
                            {(projectQueryResponse) => {
                              if (projectQueryResponse.loading) return null;
                              if (projectQueryResponse.error) return `Error!: ${projectQueryResponse.error}`;
                              const {project} = projectQueryResponse.data;
                              if(!project) return null;

                              return (
                                <PaletteColorPicker
                                  color={stateOrValue(this.state.clickBackgroundColor, buttonCell.click_background_color)}
                                  disableAlpha={true}
                                  palette={project.color_palette}
                                  onChange={(color) => {
                                    const rgb = color.rgb;
                                    this.setState({clickBackgroundColor : `rgba(${rgb.r},${rgb.g},${rgb.b},${rgb.a})`});
                                  }}
                                />
                              )
                            }}
                          </Query>
                        </div>
                      </li>
                      <li>
                        <div className={'tsk-input-line'}>
                          <label><FormattedMessage id={'cell.button.clickcolors.text.label'}/></label>
                          <Query query={queries.projects.getWithChildren} variables={{ id: projectId }}>
                            {(projectQueryResponse) => {
                              if (projectQueryResponse.loading) return null;
                              if (projectQueryResponse.error) return `Error!: ${projectQueryResponse.error}`;
                              const {project} = projectQueryResponse.data;
                              if(!project) return null;

                              return (
                                <PaletteColorPicker
                                  color={stateOrValue(this.state.clickTextColor, buttonCell.click_text_color)}
                                  disableAlpha={true}
                                  palette={project.color_palette}
                                  onChange={(color) => {
                                    const rgb = color.rgb;
                                    this.setState({clickTextColor : `rgba(${rgb.r},${rgb.g},${rgb.b},${rgb.a})`});
                                  }}
                                />
                              )
                            }}
                          </Query>
                        </div>
                      </li>
                    </ul>
                  </div>


                  <div className={'tsk-setting-section'}>
                    <label><FormattedMessage id={'cell.button.cellbackground.section.label'} /></label>
                    <ul>
                      <li>
                        <div className={'tsk-input-line'} onClick={(e) => {
                          this.setState({backgroundType: 'none'});
                        }}>
                          <input type="radio" name="backgroundType" value="none"
                                 checked={stateOrValue(this.state.backgroundType, buttonCell.background_type, {transform: (val) => val === 'none'})}
                                 readOnly={true}
                          />
                          <label><FormattedMessage id={'cell.text.background.color.none'} /></label>
                        </div>
                      </li>
                      <li>
                        <div className={'tsk-input-line'} onClick={(e) => {
                          this.setState({backgroundType: 'color'});
                        }}>
                          <input type="radio" name="backgroundType" value="color"
                                 checked={stateOrValue(this.state.backgroundType, buttonCell.background_type, {transform: (val) => val === 'color'})}
                                 readOnly={true}
                          />
                          <label htmlFor={'backgroundColor'}><FormattedMessage id={'cell.text.background.color.label'} /></label>
                          <Query query={queries.projects.getWithChildren} variables={{ id: projectId }}>
                            {(projectQueryResponse) => {
                              if (projectQueryResponse.loading) return null;
                              if (projectQueryResponse.error) return `Error!: ${projectQueryResponse.error}`;
                              const {project} = projectQueryResponse.data;
                              if(!project) return null;

                              return (
                                <PaletteColorPicker
                                  color={stateOrValue(this.state.backgroundColor, buttonCell.background_color)}
                                  disableAlpha={true}
                                  palette={project.color_palette}
                                  onChange={(color) => {
                                    const rgb = color.rgb;
                                    this.setState({backgroundColor : `rgba(${rgb.r},${rgb.g},${rgb.b},${rgb.a})`});
                                  }}
                                />
                              )
                            }}
                          </Query>
                        </div>
                      </li>
                      <li>
                        <div className={'tsk-input-line'} onClick={(e) => {
                          this.setState({backgroundType: 'image'});
                        }}>
                          <input type="radio" name="backgroundType" value="image"
                                 checked={stateOrValue(this.state.backgroundType, buttonCell.background_type, {transform: (val) => val === 'image'})}
                                 readOnly={true}
                          />
                          <label htmlFor={'backgroundImage'}><FormattedMessage id={'cell.text.background.image.label'} /></label>
                          <ImageUploader
                            value={stateOrValue(this.state.backgroundImageUri, buttonCell.background_image_uri)}
                            onUpload={(imageUri) => {
                              this.setState({backgroundImageUri : imageUri});
                            }}
                          />
                        </div>
                      </li>
                      <li>
                        <div className={'tsk-input-line'} onClick={(e) => {
                          this.setState({backgroundType: 'video'});
                        }}>
                          <input type="radio" name="backgroundType" value="video"
                                 checked={stateOrValue(this.state.backgroundType, buttonCell.background_type, {transform: (val) => val === 'video'})}
                                 readOnly={true}
                          />
                          <label htmlFor={'backgroundVideo'}><FormattedMessage id={'cell.text.background.video.label'} /></label>
                          <VideoUploader
                            value={stateOrValue(this.state.backgroundVideoUri, buttonCell.background_video_uri)}
                            onUpload={(videoUri) => {
                              this.setState({backgroundVideoUri : videoUri});
                            }}
                          />
                        </div>
                      </li>
                    </ul>
                  </div>

                  <div className={'tsk-bottom-buttons'}>
                    <button
                      className={'tsk-button'}
                      onClick={() => {

                      const data = {
                        id: cell.id,
                        text: this.state.text,
                        ...(this.getButtonBackgroundData()),
                        ...(this.getCellBackgroundData()),
                        ...(this.state.textSize !== undefined ? {text_size: this.state.textSize} : {}),
                        ...(this.state.textColor ? {text_color: this.state.textColor} : {}),
                        ...(this.state.clickTextColor ? {click_text_color: this.state.clickTextColor} : {}),
                        ...(this.state.clickBackgroundColor ? {click_background_color: this.state.clickBackgroundColor} : {}),
                        ...(this.state.font ? {font: this.state.font} : {}),
                        ...(this.state.opacity !== undefined ? {opacity: this.state.opacity} : {}),
                        ...(this.state.shape ? {shape: this.state.shape} : {}),
                        ...(this.state.horizontalAlign ? {horizontal_align: this.state.horizontalAlign} : {}),
                        ...(this.state.verticalAlign ? {vertical_align: this.state.verticalAlign} : {}),
                        ...(this.state.widthPercentage ? {width_percentage: this.state.widthPercentage} : {}),
                        ...(this.state.action_uri ? {action_uri: this.state.action_uri} : {}),
                        ...(this.state.action_value ? {action_value: this.state.action_value} : {}),
                      };
                      updateButtonCell({ variables: { input: data }})
                    }}>
                      <FormattedMessage id={'common.button.submit'} />
                    </button>
                  </div>
                </div>
              )}
            </Mutation>
          )
        }}
      </Query>
    )
  }

  getCellBackgroundData() {
    const {backgroundType, backgroundColor, backgroundImageUri, backgroundVideoUri} = this.state;
    if(!backgroundType) return {};
    return {
      background_type: backgroundType,
      background_image_uri: backgroundImageUri,
      background_video_uri: backgroundVideoUri,
      background_color: backgroundColor,
    };
  }

  getButtonBackgroundData() {
    const {buttonBackgroundType, buttonBackgroundColor, buttonBackgroundImageUri} = this.state;
    if(!buttonBackgroundType) return {};
    return {
      button_background_type: buttonBackgroundType,
      button_background_image_uri: buttonBackgroundImageUri,
      button_background_color: buttonBackgroundColor,
    };
  }
}

export default withRouter(injectIntl(ButtonCellEditor));
