import React, { Component } from 'react';
import {withRouter} from 'react-router-dom';
import {Mutation, Query} from 'react-apollo';

import '../../style/TabMenusList.scss';
import queries from "../../queries";
import TabMenuEdition from "./TabMenuEdition";
import {FormattedMessage} from "react-intl";

class TabMenusList extends Component {
  state = {
    newTabName: ''
  };

  render() {
    const { projectId } = this.props.match.params;
    const { newTabName, language } = this.state;

    return (
      <Query query={queries.projects.getWithChildren} variables={{ id: projectId }}>
        {({ loading, error, data }) => {
          if (loading) return null;
          if (error) return `Error!: ${error}`;

          const { project } = data;
          return (
            <div>
              {(!!project.languages && project.languages.length > 1) &&
              <div className={'tsk-input-line'}>
                <button
                  onClick={() => this.setState({language: undefined})}
                  className={`${!language ? 'current' : ''} tsk-button`}
                >
                  <FormattedMessage id={'i18n.language.general'} />
                </button>
                {project.languages.map((aLanguage) => (
                  <button
                    key={aLanguage.code}
                    onClick={() => this.setState({language: aLanguage})}
                    className={`${(!!language && aLanguage.code === language.code) ? 'current' : ''} tsk-button`}
                  >
                    <FormattedMessage id={`i18n.language.name.${aLanguage.code}`} defaultMessage={aLanguage.name} />
                  </button>
                ))}
              </div>
              }
              <Query query={queries.tabs.listForProjectId}  variables={{ project_id: projectId }}>
                {({ loading, error, data }) => {
                  if (loading) return 'Loading...';
                  if (error) return `Error! ${error.message}`;

                  return (
                    <ul className='tsk-tabmenu-list-container'>
                      {data.tabMenusForProjectId.map((tabMenu) => {
                        return (
                          <Mutation
                            refetchQueries={[{query:queries.tabs.listForProjectId, variables:{ project_id: projectId }}]}
                            key={tabMenu.id}
                            mutation={queries.tabs.deleteTabMenu}
                          >
                            {
                              (deleteTabMenu) => (
                                <Mutation refetchQueries={[{query: queries.tabs.listForProjectId, variables:{project_id: projectId}}]} mutation={queries.tabs.deleteTabMenuItem}>
                                  {
                                    (deleteTabMenuItem) => {
                                      return(
                                        <li className='tsk-tabmenu-list-item'>
                                          <TabMenuEdition deleteTabMenuItem={deleteTabMenuItem} deleteTabMenu={deleteTabMenu} tabMenu={tabMenu} language={language} />
                                        </li>
                                      )
                                    }
                                  }
                                </Mutation>
                              )
                            }
                          </Mutation>
                        )
                      })}
                      <li className='tsk-tabmenu-list-item'>
                        <div className={'tsk-input-line'}>
                          <div className={"tsk-tabmenu-list-content"}>
                            <label htmlFor={'text'}><FormattedMessage id={'tabmenu.name.label'} /></label>
                            <input name={'text'} value={newTabName} onChange={(e) => {
                              this.setState({newTabName: e.target.value})
                            }}/>
                          </div>

                          <Mutation
                            mutation={queries.tabs.createMenu}
                            refetchQueries={[{query:queries.tabs.listForProjectId, variables:{ project_id: projectId }}]}
                            onCompleted={() => this.setState({newTabName: ''})}
                          >
                            {(createMenu) =>
                              (
                                <button
                                  disabled={newTabName.length === 0}
                                  className={"tsk-button"}
                                  onClick={() => createMenu({variables: { input: {project_id: projectId, name: newTabName }} })}
                                >
                                  <FormattedMessage id={"common.button.new"} />
                                </button>
                              )
                            }
                          </Mutation>
                        </div>
                      </li>
                    </ul>
                  )
                }}
              </Query>
            </div>
          );
        }}
      </Query>
    );
  }
}

export default withRouter(TabMenusList);
