import React from 'react';
import { addLocaleData, IntlProvider } from 'react-intl';
// import enMessages from '../lang/en.json';
import frMessages from '../lang/fr.json';
import fr from 'react-intl/locale-data/fr';
import PropTypes from "prop-types";

addLocaleData(fr);

const messages = {
  // en: enMessages,
  fr: frMessages
};

class WithLang extends React.Component {

  static propTypes = {
    userLang: PropTypes.string,
    children: PropTypes.node
  };

  // TODO handle user settings
  // initLang() {
  //   const { userLang } = this.props;
  //   const cookieLang   = Cookie.get('lang');
  //
  //   const browserLanguages = window.navigator.languages;
  //   const browserLang      = browserLanguages && browserLanguages[ 0 ] && browserLanguages[ 0 ].split('-')[ 0 ];
  //   if (!userLang) {
  //     store.dispatch(setUserLang(cookieLang || browserLang || 'en'));
  //   }
  // }

  componentDidMount() {
    // this.initLang();
  }

  render() {
    const { children, userLang = 'fr' } = this.props;
    return (
      <IntlProvider locale={userLang} messages={messages[ userLang ]}>
        {children}
      </IntlProvider>
    );
  }
}

//TODO if using redux for user language state, ensure that the ConfirmDialog still works!

// export default connect(
//   (state) => {
//     return {
//       userLang: 'fr'//getUserLang(state)
//     };
//   },
//   () => ({})
// )(WithLang);

export default WithLang;
