import gql from "graphql-tag";

export const list = gql`
{
  devices{
    id
    physical_id
    name
    need_verify
    location
    screenshot_uri
    currentInstall{
      version
    }
    project{
      name
    }
    devicesGroup{
      id
      name
    }
    connected
  }
}`;

export const listGroup = gql`
{
    devicesGroups{
        id
        name
        location
    }
}`;


export const getDeviceById = gql`
query Device($id: ID!) {
  device(id: $id) {
    id
    name
    physical_id
    group_id
    location
    need_verify
    screenshot_uri
    project{
      id
    }
  }
}
`;

export const update = gql`
mutation updateDevice ($input: UpdateDeviceInput!) {
  updateDevice (input: $input) {
    id
    physical_id
    name
    location
    screenshot_uri
    project{
      id
      name
    }
  }
}
`;

export const unlock = gql`
mutation unlockDevice ($id: ID!) {
  unlockDevice (id: $id) {
    id
  }
}
`;

export const clear = gql`
mutation clearCacheDevice ($id: ID!) {
  clearCacheDevice (id: $id) {
    id
  }
}
`;

export const reboot = gql`
mutation rebootDevice ($id: ID!) {
  rebootDevice (id: $id) {
    id
  }
}
`;

export const screenshot = gql`
mutation screenshotDevice ($id: ID!) {
  screenshotDevice (id: $id) {
    id
    screenshot_uri
  }
}
`;

export const createDevicesGroup = gql`
mutation createDevicesGroup ($name: String!) {
  createDevicesGroup (name: $name) {
    name
  }
}
`;

export const listByGroup = gql`
query DevicesGroup($id: ID!) {
  devicesGroup(id: $id) {
    id
    name
    location
    devices{
      id
      physical_id
      name
      location
      devicesGroup{
        id
        name
      }
      currentInstall{
        version
      }
      project{
        name
      }
      connected
    }
  }
}`;

export const deleteGroup = gql`
mutation deleteDevicesGroup ($id: ID!) {
  deleteDevicesGroup (id: $id) {
    id
  }
}
`;

export const deleteDisplay = gql`
mutation deleteDisplay ($id: ID!) {
  deleteDisplay (id: $id) {
    id
  }
}
`;