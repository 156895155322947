import React from "react";
import {Mutation, Query} from "react-apollo";
import queries from "../../../queries";
import _ from "lodash";
import {FormattedMessage} from "react-intl";
import VideoUploader from "../../uploaders/VideoUploader";
import ImageUploader from "../../uploaders/ImageUploader";

class ButtonDiffuseCellEditor extends React.Component {
  state = {
    video_waiting_uri: this.props.cell.video_waiting_uri,
    video_start_uri: this.props.cell.video_start_uri,
    gpio_number: this.props.cell.gpio_number,
    isChange: false,
    image_start_uri: this.props.cell.image_start_uri,
    image_waiting_uri: this.props.cell.image_waiting_uri,
    i18n: {},
  };

  setI18nState(language, oldState, newState) {
    this.setState({i18n: {...this.state.i18n, [language.code]: {...oldState, ...newState}}});
  }

  componentDidUpdate(prevProps) {

    if (
      this.props.cell.video_waiting_uri !== prevProps.cell.video_waiting_uri ||
      this.props.cell.video_start_uri !== prevProps.cell.video_start_uri ||
      this.props.cell.gpio_number !== prevProps.cell.gpio_number ||
      this.props.cell.image_start_uri !== prevProps.cell.image_start_uri ||
      this.props.cell.image_waiting_uri !== prevProps.cell.image_waiting_uri
    ) {
      const { buttonDiffuseCell } = this.props; // Ensure `buttonDiffuseCell` is available here
      if (
        buttonDiffuseCell.video_waiting_uri !== this.state.video_waiting_uri ||
        buttonDiffuseCell.video_start_uri !== this.state.video_start_uri ||
        buttonDiffuseCell.gpio_number !== this.state.gpio_number ||
        buttonDiffuseCell.image_start_uri !== this.state.image_start_uri ||
        buttonDiffuseCell.image_waiting_uri !== this.state.image_waiting_uri
      ) {
        this.setState({
          video_waiting_uri: buttonDiffuseCell.background_color,
          video_start_uri: buttonDiffuseCell.text_form_color,
          gpio_number: buttonDiffuseCell.btn_select_color,
          image_start_uri: buttonDiffuseCell.image_start_uri,
          image_waiting_uri: buttonDiffuseCell.image_waiting_uri,
        });
      }
    }
  }

  render() {
    const { cell, language } = this.props;

    return (
      <Query query={queries.cells.button_diffuse.get} variables={{ id: cell.id }}>
        {({ loading, error, data }) => {
          if (loading) return null;
          if (error) return `Error!: ${error}`;
          const { buttonDiffuseCell } = data;

          if (!buttonDiffuseCell) return null;

          const {mutation, baseInput } = !!language ?
                      {
                        image_start_uri: (_.filter(buttonDiffuseCell.i18n, {language_code: language.code})[0] || {}).image_start_uri,
                        image_waiting_uri: (_.filter(buttonDiffuseCell.i18n, {language_code: language.code})[0] || {}).image_waiting_uri,
                        video_start_uri: (_.filter(buttonDiffuseCell.i18n, {language_code: language.code})[0] || {}).video_start_uri,
                        video_waiting_uri: (_.filter(buttonDiffuseCell.i18n, {language_code: language.code})[0] || {}).video_waiting_uri,
                        mutation: queries.cells.i18n.update,
                        baseInput: {
                          cell_id: buttonDiffuseCell.id,
                          language_code: language.code,
                        },
                      } : {
                        image_start_uri: buttonDiffuseCell.video_uri,
                        image_waiting_uri: buttonDiffuseCell.image_waiting_uri,
                        video_start_uri: buttonDiffuseCell.video_start_uri,
                        video_waiting_uri: buttonDiffuseCell.video_waiting_uri,
                        mutation: queries.cells.button_diffuse.update,
                        baseInput: {
                          id: buttonDiffuseCell.id,
                        },
          };

          return (
            <Mutation
              mutation={mutation}
              refetchQueries={() => [
                { query: queries.cells.button_diffuse.get, variables: { id: cell.id } },
              ]}
            >
              {(mutationFct) => (
                <div>
                  <label>Video de l'animation</label>
                  <VideoUploader
                    accept={".mp4"}
                    cellId={cell.id}
                    uploadPath="/video"
                    value={this.state.video_start_uri}
                    onUpload={(e) => {
                      const data = e;
                      mutationFct({
                        variables: { input: { ...baseInput, video_start_uri: data },},
                      });
                    }}
                  ></VideoUploader>
                  <br />
                  <br />
                  <label>Video en attente</label>
                  <VideoUploader
                    cellId={cell.id}
                    accept={".mp4"}
                    uploadPath="/video"
                    value={this.state.video_start_uri}
                    onUpload={(e) => {
                      const data = e;
                      mutationFct({
                        variables: {
                          input: { ...baseInput, video_waiting_uri: data },
                        },
                      });
                    }}
                  ></VideoUploader>
                  <br />
                  <br />

                  <label>Image de l'animation</label>
                  <ImageUploader onUpload={(e) => {
                      const data = e;
                      mutationFct({
                        variables: {
                          input: { ...baseInput, image_start_uri: data },
                        },
                      });
                    }}/>
                  <br />
                  <br />
                  <label>Image en attente</label>
                  <ImageUploader onUpload={(e) => {
                      const data = e;
                      mutationFct({
                        variables: {
                          input: { ...baseInput, image_waiting_uri: data },
                        },
                      });
                    }}/>
                  <br />
                  <br />
                  <label>GPIO Number</label>
                  <input
                    type="number"
                    value={this.state.gpio_number}
                    onChange={(e) =>
                      this.setState({ gpio_number: parseInt(e.target.value), isChange: true })
                    }
                  />
                  <button
                    className="tsk-button"
                    onClick={() => {
                      mutationFct({
                        variables: {
                          input: {
                            id: cell.id,
                            video_waiting_uri: this.state.video_waiting_uri,
                            video_start_uri: this.state.video_start_uri,
                            gpio_number: this.state.gpio_number,
                          },
                        },
                      });
                    }}
                  >
                    <FormattedMessage id={"common.button.submit"} />
                  </button>
                </div>
              )}
            </Mutation>
          );
        }}
      </Query>
    );
  }
}

export default ButtonDiffuseCellEditor;
