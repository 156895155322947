import React from "react";
import * as _ from "lodash";
import {FormattedMessage} from "react-intl";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Mutation, withApollo} from "react-apollo";
import queries from "../../queries";
import {withRouter} from "react-router-dom";
import Composer from "react-composer";
import {apolloClient} from "../../config";

class TabMenuEdition extends React.Component {
  state = {
    items: []
  };

  refreshStateFromProps() {
    const {tabMenu} = this.props;
    console.log("Refreshing state...");
    this.setState({
      name: tabMenu.name,
      items: tabMenu.items.map((item) =>
        ({...item, pristine: true})
      )
    })
  }

  componentDidMount() {
    this.refreshStateFromProps();
  }


  componentDidUpdate(prevProps, prevState, snapshot) {
    if(!_.isEqual(prevProps, this.props)) {
      this.refreshStateFromProps();
    }
  }

  render() {
    const {projectId} = this.props.match.params;
    const {tabMenu, client, language, deleteTabMenu, deleteTabMenuItem} = this.props;
    const {name, items} = this.state;

    return (
      <div className={"tsk-tabmenu-list-content"}>
        <div className={'tsk-input-line'}>
          {/*<label htmlFor={'text'}><FormattedMessage id={'tabmenu.name.label'} /></label>*/}
          {/*<input name={'text'} value={name} onChange={(e) => {*/}
          {/*  this.setState({name: e.target.value})*/}
          {/*}}/>*/}
          {name}
        </div>

        <ul className={"tsk-tabmenu-item-list-container"}>
        {items.map((item, idx) => {
          const value = !!language ? _.filter(item.i18n, {language_code: language.code})[0] || {language_code: language.code} : item;
          console.log("item.i18n=", item.i18n);
          return (
            <li key={item.id}>
              <div className={'tsk-input-line'}>
                <label><FormattedMessage id={'tabmenu.item.text.label'} /></label>
                <input value={value.text || ''}
                placeholder={item.text}
                onChange={(e) => {
                  const newItems = [...items];
                  newItems[idx] = !value.language_code ?
                  {...item, text: e.target.value, pristine: false} :
                  {
                    ...item,
                    i18n: [
                      ..._.reject(item.i18n, {language_code: value.language_code}),
                      {...value, text: e.target.value, pristine: false}
                    ]
                  };
                  this.setState({items: newItems})
                }
              }/>
              </div>
              <button onClick={()=>{deleteTabMenuItem({variables: {input: item.id}})}} className="tsk-button-remove" style={{color: 'red', backgroundColor: 'white'}}>
                X
              </button>
            </li>
          )
        })}
          <li className={"tsk-tabmenu-item-list-add"}>
            <button type={'button'} className={'tsk-button tsk-link'}
                    onClick={() => {
                      const newItem = {id: `new_${items.length}`, text: '', isNew: true, i18n: []};
                      this.setState({items: [...items, newItem]});
                    }}
            >
              <FontAwesomeIcon icon={'plus'} className={'tsk-bullet-point'} />
            </button>
          </li>
        </ul>
        <Composer
          components={[
            <Mutation mutation={queries.tabs.addItem} />,
            <Mutation mutation={queries.tabs.updateItem} />,
          ]}
        >
          {([addItem, updateItem]) => (
            <>
              <button className={"tsk-button"} onClick={async () => {
                for(const item of items) {
                  if (item.isNew) {
                    await addItem({variables: {input: {tab_menu_id: tabMenu.id, text: item.text}}});
                  } else if (!item.pristine) {
                    await updateItem({variables: {input: {id: item.id, text: item.text}}});
                  }
                  if(item.i18n) {
                    for (const i18n of item.i18n) {
                      if(i18n.pristine !== undefined && !i18n.pristine) {
                        await apolloClient.mutate({
                          mutation: queries.tabs.updateI18n,
                          variables: {input: {
                              tab_menu_item_id: item.id,
                              language_code: i18n.language_code,
                              text: i18n.text,
                            }},
                        })
                      }
                    }
                  }
                }
                client.query({
                  query:queries.tabs.listForProjectId,
                  variables:{ project_id: projectId },
                  fetchPolicy: "network-only",
                });
              }}>
                <FormattedMessage id={"common.button.save"} />
              </button>
              <button className="tsk-button" onClick={()=>{deleteTabMenu({variables: {input: tabMenu.id}})}}>
                <FormattedMessage id={"common.button.delete"} />
              </button>
            </>
          )}
        </Composer>
      </div>
    )
  }

}

export default withApollo(withRouter(TabMenuEdition));
