import gql from "graphql-tag";

export const get = gql`
query PhotoDatas($id: ID!) {
  photoDatas(id: $id) {
    id
    image_uri
  }
}
`;
