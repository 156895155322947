import React, {Component} from "react";
import { Mutation, Query } from "react-apollo";
import queries from "../../queries";
import { FormattedMessage } from "react-intl";
import '../../style/DisplayManagement.scss'
import NeedAdmin from "../NeedAdmin";
import { apolloClient } from "../../config";
import confirmDialog from "../dialogs/ConfirmDialog";

class DisplayManagement extends Component{
    
    constructor(props){
        super(props);
        this.state = {project_id : '', name: '', filterText: '', filterGroup: "", group_id: null, group_change: false, location: '', location_change: false, alerte: false, alerteChanged: false};
    }
    
    render(){
        const { displayId } = this.props.match.params;
        return( 
            <NeedAdmin trueAdmin>
            <Query query={queries.displays.getDeviceById} variables={{ id: displayId }}>
            {
                ({ loading, error, data }) => {
                    if(loading){
                        return 'Loading';
                    }
                    if(error){
                        return error.message;
                    }

                    const device = data.device;

                    console.log(device);

                    if(device.location !== this.state.location && !this.state.location_change){
                        this.setState({location: device.location});
                        console.log(device.location);
                    }

                    if(!this.state.alerteChanged){
                        this.setState({alerte: device.need_verify, alerteChanged: true});
                    }
                    
                    return(
                        <div className="tsk-container-display-edit">
                        <button className="tsk-btn-update" onClick={()=>{window.location.href = (device.group_id == null) ? '/admin/displaysGroup/bureau' : '/admin/displaysGroup/' + device.group_id}}>
                            Retour
                        </button>
                        <div className="tsk-display-management-row">
                        <label><FormattedMessage id='display.mananagement.display_id'></FormattedMessage> {device.physical_id}</label>
                        <br/>
                        <br/>
                        <Mutation mutation={queries.displays.update}>
                        {
                            (updateName)=>{
                                return(
                                    <>
                                    <label htmlFor="name"><FormattedMessage id='display.mananagement.display_name'></FormattedMessage>{(device.name == null) ? 'No name' : device.name}</label>
                                    <input className="tsk-input-lines" onChange={(e)=>{
                                        this.setState({name: e.target.value});
                                    }} value={this.state.name} type="text" id="name" minLength={6} maxLength={255} placeholder="Name of display"></input>
                                    <button className="tsk-btn-update" onClick={()=>{
                                        updateName({variables:{input:{id: displayId, name: this.state.name}}})}
                                    }><FormattedMessage id='display.mananagement.rename'></FormattedMessage></button>
                                    </>
                                    )
                                }
                            }
                            </Mutation>
                            <br/>
                            <br/>
                            <Query query={queries.displays.listGroup}>
                                {
                                    ({loading, error, data})=>{
                                        if(loading){
                                            return 'Loading';
                                        }
                                        if(error){
                                            return error.message;
                                        }
                                        const groups = data.devicesGroups

                                        if(this.state.group_id !== device.group_id && !this.state.group_change){
                                            this.setState({group_id: device.group_id});
                                        }

                                        return(
                                            <Mutation mutation={queries.displays.update}>
                                            {
                                                (update)=>{
                                                    return(
                                                        <>
                                                            <label><FormattedMessage id='display.management.change_group'></FormattedMessage></label>
                                                            <select className="tsk-input-lines" value={this.state.group_id} onChange={(e)=>{this.setState({group_change: true, group_id: e.target.value})}}>
                                                                <option value={''}>
                                                                    Bureau
                                                                </option>
                                                                {
                                                                    groups.map((group)=>{
                                                                        if(group.name.toLocaleLowerCase().includes(this.state.filterGroup.toLocaleLowerCase())){
                                                                            return(
                                                                                <option key={group.id} value={group.id}>
                                                                                    {group.name}
                                                                                </option>
                                                                            );
                                                                        }else{
                                                                            return null;
                                                                        }
                                                                    })
                                                                }
                                                            </select>
                                                            <input className="tsk-input-lines" placeholder="Filter" type="text" onChange={(e)=>{this.setState({filterGroup: e.target.value})}} value={this.state.filterGroup}/>
                                                            <button className="tsk-btn-update" onClick={()=>{update({variables:{input:{id: device.id, group_id: (this.state.group_id.length > 0) ? this.state.group_id : null}}})}}>
                                                                <FormattedMessage id="display.management.btn_change"/>
                                                            </button>
                                                        </>
                                                    )
                                                }
                                            }
                                        </Mutation>
                                        )
                                    }
                                }
                            </Query>
                            <br/>
                            <br/>
                            <label><FormattedMessage id='display.mananagement.change_interface'></FormattedMessage></label>
                            <Query query={queries.projects.list}>
                            {({ loading, error, data }) => {
                                if (loading) return 'Loading...';
                                if (error) return `Error! ${error.message}`;
                                
                                return (
                                    <select className="tsk-input-lines" value={(device.project == null) ? '' : device.project.id} onChange={(e)=>{
                                        const project_id = e.target.value;
                                        this.setState({project_id: project_id})
                                        device.project = {id: project_id};
                                        }}>
                                        <option value={''}>No project</option>
                                        {data.projects.map((project) => {
                                        if(project.name.toLocaleLowerCase().includes(this.state.filterText.toLocaleLowerCase())){
                                            return (
                                                <option value={project.id} key={project.id}>
                                                {project.name}
                                                </option>
                                                )
                                            }else{
                                                return null;
                                            }
                                        })}
                                        </select>
                                        )
                                    }}
                                </Query>
                                <input className="tsk-input-lines" type="text" placeholder="Filtrer" value={this.state.filterText} onChange={(e)=>{this.setState({filterText: e.target.value})}}/>
                                    <Mutation mutation={queries.displays.update}>
                                    {(updateDisplay)=>{
                                        return(
                                            <button className="tsk-btn-update" onClick={()=>{
                                                if(this.state.project_id !== ''){
                                                    updateDisplay({variables: {input: {project_id: this.state.project_id, id: device.id}}});
                                                }
                                            }}>
                                            <FormattedMessage id='display.mananagement.change'></FormattedMessage>
                                            </button>
                                            );
                                        }}
                                        </Mutation>
                                        </div>
                                        <br/>
                                        <Mutation mutation={queries.displays.update}>
                                        {(updateLocation)=>{
                                        return(
                                            <div>
                                                <textarea onChange={(e)=>{this.setState({location: e.target.value, location_change: true}); console.log(this.state)}} className="tsk-input-lines" placeholder="Location" cols={50} rows={10} value={this.state.location}>{device.location}</textarea>
                                                <br/>
                                                <button onClick={()=>{updateLocation({variables:{input:{location: this.state.location, id: device.id}}})}} className="tsk-btn-update">Modify location</button>
                                            </div>
                                        )
                                        }}
                                        </Mutation>
                                        <br/>
                                        <br/>
                                        <Mutation mutation={queries.displays.update}>
                                            {
                                                (update)=>{
                                                    return(
                                                        <div>
                                                            <input id="alerte" type="checkbox" checked={this.state.alerte} onChange={(e)=>{this.setState({alerte: !this.state.alerte})}}/>
                                                            <label htmlFor="alerte">
                                                                <FormattedMessage id="treeosk.displays.alert_display"/>
                                                            </label>
                                                            <button className="tsk-btn-update" onClick={()=>{update({variables: {input: {id: device.id, need_verify: this.state.alerte}}})}}>
                                                                    <FormattedMessage id="common.button.save"/>
                                                            </button>
                                                        </div>
                                                    )
                                                }
                                            }
                                        </Mutation>
                                        <br/>
                                        <br/>
                                        <br/>
                                        <br/>
                                        <Mutation mutation={queries.displays.unlock}>
                                            {
                                                (unlockDevice)=>{
                                                    return(
                                                        <button className="tsk-btn-update" onClick={()=>{unlockDevice({variables: {id:displayId}})}}>
                                                            <FormattedMessage id='display.mananagement.unlock'></FormattedMessage>
                                                        </button>
                                                    )
                                                }
                                            }
                                        </Mutation>
                                        <br/>
                                        <br/>
                                        <Mutation mutation={queries.displays.clear}>
                                            {
                                                (clear)=>{
                                                    return(
                                                        <button className="tsk-btn-update" onClick={()=>{clear({variables: {id:displayId}})}}>
                                                            <FormattedMessage id='display.mananagement.cachclear'></FormattedMessage>
                                                        </button>
                                                    )
                                                }
                                            }
                                        </Mutation>
                                        <br/>
                                        <br/>
                                        <Mutation mutation={queries.displays.reboot}>
                                            {
                                                (reboot)=>{
                                                    return(
                                                        <button className="tsk-btn-update" onClick={()=>{reboot({variables: {id:displayId}})}}>
                                                            <FormattedMessage id='display.mananagement.reboot'></FormattedMessage>
                                                        </button>
                                                    )
                                                }
                                            }
                                        </Mutation>
                                        <br/>
                                        <br/>
                                        <Mutation refetchQueries={queries.displays.getDeviceById} variables={{input:{id: device.id}}} mutation={queries.displays.screenshot}>
                                            {
                                                (screenshot)=>{
                                                    return(
                                                        <button className="tsk-btn-update" onClick={()=>{screenshot({variables: {id:displayId}})}}>
                                                            <FormattedMessage id='display.mananagement.screenshot'></FormattedMessage>
                                                        </button>
                                                    )
                                                }
                                            }
                                        </Mutation>
                                        <br/>
                                        <br/>
                                        <div style={{borderRadius: '10px',border: 'solid 10px black',width: 360, height: 640, overflow: 'hidden', background: device.screenshot_uri ? `url(${device.screenshot_uri})` : 'black', backgroundSize: 'cover', backgroundPosition: 'center'}}>
                                            {
                                                !device.screenshot_uri && <div style={{color: 'white', textAlign: 'center', paddingTop: 300}}>No screenshot</div>
                                            }
                                        </div>
                                        <br/>
                                        <br/>
                                        <Mutation mutation={queries.displays.deleteDisplay}>
                                            {
                                                (deleteDisplay)=>{
                                                    return(
                                            <button
                                            className={'tsk-button tsk-warning'}
                                            onClick={() => {
                                                confirmDialog('Delete this device now').then(res => {
                                                apolloClient.mutate({
                                                mutation: queries.displays.deleteDisplay,
                                                variables: {id: displayId},
                                                refetchQueries: [{query: queries.displays.listGroup}]
                                                }).then((res) => {
                                                    if(!res.errors && res.data) {
                                                        window.location.href  = '/admin/displays';
                                                    }
                                                    });
                                                    });
                                                }}>
                                                <FormattedMessage id={'common.button.delete'} />
                                                    </button>                       
                                                    )
                                                }
                                            }
                                        </Mutation>
                                        <br/>
                                        <br/>
                                        </div>
                                        )
                                    }
                                }
                                </Query>
                                </NeedAdmin>
                                );
                            }
                        }
                        
                        export default DisplayManagement;