import React from "react";
import {Mutation, Query} from "react-apollo";
import queries from "../../queries";
import {withRouter} from "react-router-dom";
import {FormattedMessage} from "react-intl";
import BackgroundSelector from "../BackgroundSelector";

class PageSettings extends React.Component  {

  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
    this.state = {
      ...(props.page ? {pageName: props.page.name}: {})
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const prevPage = prevProps.page;
    const newPage = this.props.page;
    if (!!newPage && (!prevPage || (prevPage.name !== newPage.name))) {
      this.setState({
        pageName: newPage.name
      })
    }
  }

  render() {
    const { pageName } = this.state;
    const { page } = this.props;
    const { projectId, pageId } = this.props.match.params;

    return (
      <Mutation
        mutation={queries.pages.update}
        refetchQueries={() => [
          { query: queries.pages.getWithChildren, variables: {id: pageId} },
          { query: queries.projects.getWithChildren, variables: {id: projectId} }
        ]}
      >
        {(updatePage) => (
          <Query query={queries.projects.getWithChildren} variables={{ id: projectId }}>
            {(projectQueryResponse) => {
              if (projectQueryResponse.loading) return null;
              if (projectQueryResponse.error) return `Error!: ${projectQueryResponse.error}`;
              const {project} = projectQueryResponse.data;
              if (!project) return null;

              return (
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    updatePage({ variables: { input: {
                      id: pageId,
                      name: pageName,
                      background_type: this.state.backgroundType,
                      background_image_uri: this.state.backgroundImageUri,
                      background_video_uri: this.state.backgroundVideoUri,
                      background_color: this.state.backgroundColor,
                    }} });
                  }}
                >
                  <div className={'tsk-form-content'}>
                    <label htmlFor={'name'}>
                      <FormattedMessage id={'page.edition.name'} />
                    </label>
                    <input name={'name'} ref={this.inputRef} value={pageName}
                           onChange={(e) => this.setState({pageName: e.target.value})} />
                  </div>

                  <BackgroundSelector
                    value={page}
                    palette={project.color_palette}
                    onChange={(bgValues) => this.setState(bgValues)}
                  />
                  <br/>
                  <div className={'tsk-form-bottom'}>
                    <button className={'tsk-button'} type="submit">
                      <FormattedMessage id={'common.button.submit'} />
                    </button>
                  </div>
                </form>
              )
            }}
          </Query>
        )}
      </Mutation>
    )
  }
}

export default withRouter(PageSettings);
