import React, {Fragment} from "react";
import {Mutation, Query} from "react-apollo";
import queries from "../../../queries";
import {Route} from "react-router-dom";
import BackgroundSelector from "../../BackgroundSelector";
import * as _ from "lodash";
import {FormattedMessage, injectIntl} from "react-intl";

import '../../../style/TabMenu.scss';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import PaletteColorPicker from "../../PaletteColorPicker";

class TabMenuCellEditor extends React.Component  {
  state = {
    textSize: 1,
    font: 'Arial',
    textColor: '#000',
  };

  render() {
    const { cell, intl } = this.props;

    return (
      <Route path='/project/:projectId/page/:pageId' render={ ({match}) => {
        const {projectId} = match.params;

        return (
          <Query query={queries.cells.tabs.get} variables={{id: cell.id}}>
            {({loading, error, data}) => {
              if (loading) return null;
              if (error) return `Error!: ${error}`;

              const {tabMenuCell} = data;
              if (!tabMenuCell) return null;
              const linkToNoneTxt = intl.formatMessage({id: 'cell.links.selector.none'});
              const menuSelectorValue = tabMenuCell.tab_menu_id ? tabMenuCell.tab_menu_id : '';

              console.log("projectId=", projectId);
              return (
                <Query query={queries.projects.getWithChildren} variables={{ id: projectId }}>
                  {({ loading, error, data }) => {
                    if (loading) return null;
                    if (error) return `Error!: ${error}`;

                    const {project} = data;

                    return (
                      <Mutation
                        mutation={queries.cells.tabs.update}
                        refetchQueries={[{query:queries.cells.tabs.get, variables:{id: cell.id}}]}
                      >
                        {(updateCell) => (
                          <Query query={queries.tabs.listForProjectId} variables={{project_id: projectId}}>
                            {({loading, error, data}) => {
                              if (loading) return 'Loading...';
                              if (error) return `Error! ${error.message}`;

                              const tabMenus = data.tabMenusForProjectId;

                              const tabMenuSelector = (
                                <div className={'tsk-input-line'}>
                                  <label htmlFor={'tab-menu'}><FormattedMessage id={'cell.tabmenu.menu.label'} /></label>
                                  <select name={'tab-menu'} value={ menuSelectorValue } onChange={(e) => {
                                    const newMenu = e.target.value === '' ? null : e.target.value;
                                    if(newMenu !== tabMenuCell.tab_menu_id) {
                                      updateCell({ variables:{ input: {id: tabMenuCell.id, tab_menu_id: newMenu}}});
                                    }
                                  }}>
                                    <option value={''}>{linkToNoneTxt}</option>
                                    {tabMenus.map((tabMenu) => (
                                      <option key={tabMenu.id} value={tabMenu.id}>{tabMenu.name}</option>
                                    ))}
                                  </select>
                                </div>
                              );

                              if (!!tabMenuCell.tab_menu_id) {
                                const tabMenu = tabMenus.filter((t) => t.id === tabMenuCell.tab_menu_id)[0];

                                return (
                                  <Fragment>
                                    {tabMenuSelector}
                                    <div className={'tsk-tabmenu-items-container'}>
                                      <div className={'tsk-tabmenu-item-line'}>
                                        <div className={'tsk-tabmenu-item-content'} />
                                        <div className={'tsk-tabmenu-item-content'} />
                                        <div className={'tsk-tabmenu-item-content'}>
                                          <FormattedMessage id={"cell.tabmenu.current_item"} />
                                        </div>
                                      </div>
                                      {tabMenu.items.map((tabItem) => {
                                        const selectValue = tabItem.to_page_id ? tabItem.to_page_id : '';

                                        return (
                                          <div key={tabItem.id} className={'tsk-tabmenu-item-line'}>
                                            <div className={'tsk-tabmenu-item-content'}>{tabItem.text}</div>
                                            <div className={'tsk-tabmenu-item-content'}>
                                              <Mutation
                                                mutation={queries.tabs.updateItem}
                                                refetchQueries={() => [
                                                  {query: queries.tabs.listForProjectId, variables:{project_id: projectId}},
                                                  {query: queries.tabs.get, variables:{id: tabMenu.id}},
                                                ]}
                                              >
                                                {(updateTabMenuItem) => (
                                                  <select name={'cell-links-to'} value={ selectValue } onChange={(e) => {
                                                    const newLink = e.target.value === '' ? null : e.target.value;
                                                    if(newLink !== tabItem.to_page_id) {
                                                      updateTabMenuItem({ variables:{ input: {id: tabItem.id, to_page_id: newLink}}});
                                                    }
                                                  }}>
                                                    <option value={''}>{linkToNoneTxt}</option>
                                                    {project.pages.map((page) => (
                                                      <option key={page.id} value={page.id}>{page.name}</option>
                                                    ))}
                                                  </select>
                                                )}
                                              </Mutation>
                                            </div>
                                            <div className={'tsk-tabmenu-item-content'}>
                                              {tabMenuCell.current_item_id === tabItem.id ?
                                                <FontAwesomeIcon className={'tsk-link-icon'} icon={['far', 'check-square']} onClick={() => {
                                                  updateCell({ variables:{ input: {id: tabMenuCell.id, current_item_id: null}}})
                                                }}/>
                                                :
                                                <FontAwesomeIcon className={'tsk-link-icon'} icon={['far', 'square']} onClick={() => {
                                                  updateCell({ variables:{ input: {id: tabMenuCell.id, current_item_id: tabItem.id}}})
                                                }}/>
                                              }
                                            </div>
                                          </div>
                                        )
                                      })}
                                    </div>
                                    <Mutation
                                      mutation={queries.tabs.update}
                                      refetchQueries={() => [{query: queries.cells.tabs.get, variables: {id: cell.id}}]}
                                    >
                                      {(updateTabMenu) => (
                                        <Fragment>
                                          <div className={'tsk-input-line'}>
                                            <label htmlFor={'textSize'}><FormattedMessage id={'cell.text.size.label'} /></label>

                                            <input name={'textSize'}
                                                   type={'range'}
                                                   min={0}
                                                   max={3}
                                                   step={0.1}
                                                   value={tabMenu.text_size}
                                                   onChange={(e) =>
                                                     updateTabMenu({variables: {input: {
                                                           id: tabMenu.id,
                                                           text_size : parseFloat(e.target.value),
                                                         }}})
                                                   }
                                            />
                                            <span>{tabMenu.text_size}</span>
                                          </div>

                                          <div className={'tsk-setting-section'}>
                                            <label><FormattedMessage id={'cell.tabmenu.current_tab.section.label'} /></label>
                                            <div className={'tsk-input-line'}>
                                              <label htmlFor={'textColor'}><FormattedMessage id={'cell.text.color.label'} /></label>
                                              <PaletteColorPicker
                                                name={"textColor"}
                                                color={tabMenu.current_text_color}
                                                disableAlpha={false}
                                                palette={project.color_palette}
                                                onChange={(color) => {
                                                  const rgb = color.rgb;
                                                  updateTabMenu({variables: {input: {
                                                    id: tabMenu.id,
                                                    current_text_color: `rgba(${rgb.r},${rgb.g},${rgb.b},${rgb.a})`
                                                  }}});
                                                }}
                                              />
                                            </div>
                                            <div className={'tsk-input-line'}>
                                              <label htmlFor={'font'}><FormattedMessage id={'cell.text.font.label'} /></label>
                                              <select
                                                value={tabMenu.current_font}
                                                onChange={(e) => {
                                                  updateTabMenu({variables: {input: {id: tabMenu.id, current_font: e.target.value}}});
                                                }}
                                              >
                                                {project.fonts.map((font) => <option key={font.id} value={font.name}>{font.name}</option>)}
                                              </select>
                                            </div>
                                          </div>

                                          <div className={'tsk-setting-section'}>
                                            <label><FormattedMessage id={'cell.tabmenu.other_tabs.section.label'} /></label>
                                            <div className={'tsk-input-line'}>
                                              <label htmlFor={'textColor'}><FormattedMessage id={'cell.text.color.label'} /></label>
                                              <PaletteColorPicker
                                                name={"textColor"}
                                                color={tabMenu.text_color}
                                                disableAlpha={false}
                                                palette={project.color_palette}
                                                onChange={(color) => {
                                                  const rgb = color.rgb;
                                                  updateTabMenu({variables: {input: {
                                                        id: tabMenu.id,
                                                        text_color: `rgba(${rgb.r},${rgb.g},${rgb.b},${rgb.a})`
                                                      }}});
                                                }}
                                              />
                                            </div>
                                            <div className={'tsk-input-line'}>
                                              <label htmlFor={'font'}><FormattedMessage id={'cell.text.font.label'} /></label>
                                              <select
                                                value={tabMenu.font}
                                                onChange={(e) => {
                                                  updateTabMenu({variables: {input: {id: tabMenu.id, font: e.target.value}}});
                                                }}
                                              >
                                                {project.fonts.map((font) => <option key={font.id} value={font.name}>{font.name}</option>)}
                                              </select>
                                            </div>
                                          </div>


                                          <BackgroundSelector
                                            value={tabMenu}
                                            palette={project.color_palette}
                                            onChange={(bgValues) => {
                                              const mutationInput = {
                                                ...(!!bgValues.backgroundType ? {background_type: bgValues.backgroundType} : {}),
                                                ...(!!bgValues.backgroundImageUri ? {background_image_uri: bgValues.backgroundImageUri} : {}),
                                                ...(!!bgValues.backgroundVideoUri ? {background_video_uri: bgValues.backgroundVideoUri} : {}),
                                                ...(!!bgValues.backgroundColor ? {background_color: bgValues.backgroundColor} : {}),
                                              };
                                              if(!_.isEmpty(mutationInput)) {
                                                console.log("Updating menu with: ", mutationInput);
                                                updateTabMenu({variables: {input: {...mutationInput, id: tabMenu.id}}});
                                              }
                                            }}
                                          />
                                        </Fragment>
                                      )}
                                    </Mutation>
                                  </Fragment>
                                )
                              }

                              return tabMenuSelector;
                            }}
                          </Query>
                        )}
                      </Mutation>
                    )
                  }}
                </Query>
              )
            }}
          </Query>
        );
      }}/>
    );
  }
}

export default injectIntl(TabMenuCellEditor);
