import React from "react";
import { Component } from "react";
import NeedAdmin from "../NeedAdmin";
import queries from "../../queries";
import { Mutation, Query } from "react-apollo";
import '../../style/GamePrizes.scss'
import { FormattedMessage } from "react-intl";
import ImageUploader from "../uploaders/ImageUploader";
import CalendarApp from "./CalendarPeriodes";

class GamePrizesEdit extends Component{

    constructor(){
        super();
        this.state = {
            estimated_nb_players: 1,
            default_prize_id: null,
            changed: false,
            index_slot: 0,
            image: null,
            label: '',
        }
    }

    render(){
        const {gameId, projectId} = this.props.match.params;
        
        return(
            <NeedAdmin trueAdmin>
                <Query query={queries.games.getGameById} variables={{ id: gameId }}>
                {
                    ({ loading, error, data }) => {
                    if(loading){
                        return 'Loading';
                    }
                    if(error){
                        return error.message;
                    }
                    const game = data.game;
                    const prizes = game.prizes;

                    const events = [];

                    prizes.map((prize)=>{
                        prize.periodes.map((periode)=>{
                            const periode_for_calandar = {
                                id: periode.id,
                                title: prize.label + " (quantity : " + periode.qts_for_periode + ")",
                                start: periode.periode_start,
                                end: periode.periode_end
                            };
                            events.push(periode_for_calandar);

                            return true;
                        })
                        return true;
                    })

                    console.log(game);

                    return(
                        <div className="trsk-container">
                            <Mutation mutation={queries.games.update}>
                                {
                                    (mutationName)=>{
                                        if((!this.state.changed) && (game.estimated_nb_players !== this.state.estimated_nb_players || (game.default_prize === undefined && game.default_prize.id !== this.state.default_prize_id))) {
                                            this.setState({
                                                ...(game.estimated_nb_players !== this.state.estimated_nb_players ? {estimated_nb_players: game.estimated_nb_players} : {}),
                                                ...(game.default_prize !== null ? {default_prize_id: game.default_prize.id} : {}),
                                            });
                                        }
                                        return(
                                            <div className="trsk-edit-card tsk-mt">
                                            <label htmlFor="players">
                                            <FormattedMessage id="game.edit.estimated_players"></FormattedMessage>
                                            </label>
                                            <input className="tsk-input-prize" id="players" placeholder="Nombre de joueurs estimé" onChange={(e)=>{this.setState({estimated_nb_players: e.target.value, changed: true})}} value={this.state.estimated_nb_players} type="number" min={1}/>
                                            <br/>
                                            <br/>
                                            <label>
                                            <FormattedMessage id="game.edit.default_slot"></FormattedMessage>
                                            </label>
                                            <br/>
                                            <select value={this.state.default_prize_id} className="tsk-select tsk-select-prize" onChange={(e)=>{
                                                this.setState({default_prize_id: e.target.value, changed: true});
                                            }}>
                                                {
                                                    prizes.map((prize)=>{
                                                        return(
                                                            <option className="tsk-option" value={prize.id} key={prize.id}>
                                                            {'label : ' + prize.label + ' | slot : ' + prize.slot_index}
                                                            </option>
                                                        )
                                                    })
                                                }
                                            </select>
                                            <br/>
                                            <br/>
                                            <button className="tsk-button" onClick={()=>{mutationName({variables:{input:{id: game.id, estimated_nb_players: parseInt(this.state.estimated_nb_players), default_prize_id: this.state.default_prize_id}}})}}>
                                            <FormattedMessage id="page.edition.submit.button"></FormattedMessage>
                                            </button>
                                            </div>
                                        )
                                    }
                                }
                            
                            </Mutation>

                                <Query query={queries.projects.getLanguagesForProject} variables={{id: projectId}}>
                                {
                                    ({loading, error, data})=>{
                                        if(loading){
                                            return 'loading'
                                        }
                                        if(error){
                                            return error.message;
                                        }

                                        return(
                                            <Mutation mutation={queries.games.update}>
                                {
                                    (mutationName)=>{
                                        return(
                                            <div className="trsk-edit-card tsk-mt">
                                                
                                                {
                                                    prizes.map((prize)=>{
                                                        return (
                                                            <div key={prize.id}>
                                                                <Mutation mutation={queries.games.updatePrizes}>
                                                                    {
                                                                        (updatePrize)=>{
                                                                            return(
                                                                            <div>
                                                                                <a href={'/project/' + projectId + '/prize/' + prize.id} className="tsk-link-prize">
                                                                                    {prize.label}
                                                                                </a>
                                                                                <ImageUploader value={prize.image_uri} onUpload={(uri)=>{updatePrize({variables:{ input:{image_uri: uri, id: prize.id}}})}}/>
                                                                            </div>
                                                                            )
                                                                        }
                                                                    }
                                                                </Mutation>
                                                                <hr/>
                                                            </div>
                                                        );
                                                    })
                                                }
                                            </div>
                                        );
                                    }
                                }
                                </Mutation>
                                        )
                                    }
                                }
                            </Query>

                            <Mutation mutation={queries.games.create}>
                                {
                                    (mutationName)=>{
                                            return(
                                                <div className="trsk-edit-card tsk-mt">
                                                    <h1>
                                                    <FormattedMessage id="game.edit.title.add"></FormattedMessage>
                                                    </h1>
                                                    <FormattedMessage id="game.edit.slot"></FormattedMessage>
                                                <input className="tsk-input-prize" type="number" min={0} max={64} value={this.state.index_slot} onChange={(e)=>{this.setState({index_slot: e.target.value})}}/>
                                                <br/>
                                                <br/>
                                                <FormattedMessage id="game.edit.label"></FormattedMessage>
                                                <input className="tsk-input-prize" type="text" placeholder="label" maxLength={255} minLength={3} value={this.state.label} onChange={(e)=>{this.setState({label: e.target.value})}}/>
                                                <br/>
                                                <br/>
                                                <button className="tsk-button" onClick={()=>{mutationName({variables:{input:{game_id: game.id, index_slot: parseInt(this.state.index_slot), label: this.state.label}}}); window.location.reload()}}>
                                                    <FormattedMessage id="common.button.new"></FormattedMessage>
                                                </button>
                                                </div>
                                                );
                                    }
                                }
                            </Mutation>
                            <CalendarApp events={events}/>
                        </div>
                        );
                    }
                }

                </Query>
            </NeedAdmin>
        )
    }
}

export default GamePrizesEdit;