import gql from "graphql-tag";

export default {
    update: gql`
    mutation UpdateButtonDiffuseCell ($input: UpdateButtonDiffuseCellInput!) {
        updateButtonDiffuseCell (input: $input) {
            id
            cell_type
            video_waiting_uri
            video_start_uri
            gpio_number
            image_waiting_uri
            image_start_uri
            i18n {
                language_code
                text
                video_waiting_uri
                video_start_uri
                image_start_uri
                image_waiting_uri
            }
        }
    }`,

get: gql`
    query ButtonDiffuseCell($id: ID!) {
        buttonDiffuseCell(id: $id) {
            id
            cell_type
            video_waiting_uri
            video_start_uri
            gpio_number
            image_waiting_uri
            image_start_uri
            i18n {
                language_code
                text
                video_waiting_uri
                video_start_uri
                image_start_uri
                image_waiting_uri
            }
        }
    }`
}
