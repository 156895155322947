import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { DragDropContext } from 'react-dnd';
import { default as TouchBackend } from 'react-dnd-touch-backend';
import {
  BrowserRouter as Router,
  Route, Redirect, Switch,
} from 'react-router-dom';
import { LastLocationProvider } from 'react-router-last-location';

import { ApolloProvider } from 'react-apollo';
import { identityProvider } from './utils';

import { apolloClient } from './config';
import * as serviceWorker from './serviceWorker';

import './icons';
import './style/Main.scss';

import WithLang from './components/WithLang';

import ProjectsList from "./components/projects/ProjectsList";
import Layout from "./components/main/Layout";
import ProjectCreation from "./components/projects/ProjectCreation";
import ProjectEdition from "./components/projects/ProjectEdition";
import NeedAuth from "./components/NeedAuth";
import ProjectPreview from "./components/projects/ProjectPreview";
import PhotoView from "./components/photo/PhotoView";
import UsersManagement from "./components/UsersManagement";
import TabMenusList from "./components/tabs/TabMenusList";
import ProductsList from "./components/products/ProductsList";
import DisplaysList from './components/displays/DisplaysList';
import DisplayManagement from './components/displays/DisplayManagement';
import GamePrizes from './components/prizes/GamePrizes';
import GamePrizesEdit from './components/prizes/GamePrizesEdit';
import DisplayGroupsList from './components/displays/DisplayGroupsList';
import GamePrizePeriodeEdit from './components/prizes/GamePrizePeriodeEdit';

if (process.env.NODE_ENV !== 'production') {
  const whyDidYouRender = require('@welldone-software/why-did-you-render');
  whyDidYouRender(React);
}

identityProvider.init();

let dragOptions = {
  enableTouchEvents: true,
  enableMouseEvents: true,
  delayTouchStart: true
};

class _App extends Component {

  static propTypes = {
    // config: PropTypes.object.isRequired
  };

  render() {
    // const { config } = this.props;
    return (
      <WithLang>
        <ApolloProvider client={apolloClient}>
          <Router>
            <LastLocationProvider>
              <Route exact path='/' render={() => (
                <Redirect from="/" to="/project" />
              )}/>
              <Switch>
                {/* Public routes, probably only linked to authentication */}
                {/*<Route path='/login' component={Login}/>*/}
                {/*<Route path='/signup/confirm/:token' component={SignupConfirm}/>*/}
                {/*<Route path='/signup' exact component={Signup}/>*/}
                {/*<Route path='/forgot' component={ForgotPassword}/>*/}
                {/*<Route path='/reset/:token' component={ResetPassword}/>*/}
                <Route path='/project/:projectId/preview' component={ProjectPreview}/>
                <Route path='/photo/:photoDatasId' component={PhotoView}/>
                <Route path='/' render={() => (
                  <Layout identityProvider={identityProvider}>
                    <NeedAuth identityProvider={identityProvider}>
                      <Switch>
                        <Route exact path='/admin/users' component={UsersManagement} />
                        <Route exact path='/project/new' component={ProjectCreation}/>
                        <Route path='/project/:projectId/page/:pageId/cell/:cellId' component={ProjectEdition}/>
                        <Route path='/project/:projectId/page/:pageId' component={ProjectEdition}/>
                        <Route exact path='/project/:projectId/tabMenu' component={TabMenusList}/>
                        <Route exact path='/project/:projectId/products' component={ProductsList}/>
                        <Route exact path='/project/:projectId' component={ProjectEdition}/>
                        <Route exact path='/project' component={ProjectsList}/>
                        <Route path='/admin/displays/:displayId' component={DisplayManagement}/>
                        <Route path='/admin/displaysGroup/:groupId' component={DisplaysList}/>
                        <Route exact path='/admin/displays' component={DisplayGroupsList}/>
                        <Route exact path='/project/:projectId/gameprizes' component={GamePrizes}/>
                        <Route exact path='/project/:projectId/gameprizes/:gameId' component={GamePrizesEdit}/>
                        <Route exact path='/project/:projectId/prize/:prizeId' component={GamePrizePeriodeEdit}/>
                      </Switch>
                    </NeedAuth>
                  </Layout>
                )}/>
              </Switch>
            </LastLocationProvider>
          </Router>
        </ApolloProvider>
      </WithLang>
    );
  }
}

const App = DragDropContext(TouchBackend(dragOptions))(_App);

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
