import React from 'react';
import PropTypes from 'prop-types';
import { createConfirmation, confirmable } from 'react-confirm';
import {FormattedMessage} from "react-intl";
import WithLang from "../WithLang";

class ConfirmDialog extends React.Component {
  static propTypes = {
    show: PropTypes.bool,            // from confirmable. indicates if the dialog is shown or not.
    proceed: PropTypes.func,         // from confirmable. call to close the dialog with promise resolved.
    cancel: PropTypes.func,          // from confirmable. call to close the dialog with promise rejected.
    dismiss: PropTypes.func,         // from confirmable. call to only close the dialog.
    confirmation: PropTypes.node,    // arguments of your confirm function
    options: PropTypes.object,       // arguments of your confirm function
  };


  render() {
    const {show, proceed, cancel, confirmation} = this.props;
    return (
      <WithLang>
        <div className={'tsk-dialog-container'} style={ show ? {} : {display : 'none'}}>
          <div className={'tsk-dialog'}>
            <div className={'tsk-dialog-content'}>
              {confirmation}
            </div>
            <div className={'tsk-dialog-buttons'}>
              <button className={'tsk-button'} onClick={() => cancel()}>
                <FormattedMessage id={'dialog.cancel.button'} />
              </button>
              <button className={'tsk-button'} style={{marginLeft: '1em'}} onClick={() => proceed()}>
                <FormattedMessage id={'dialog.ok.button'} />
              </button>
            </div>
          </div>
        </div>
      </WithLang>
    )
  }
}

// create confirm function
const confirmDialog = createConfirmation(confirmable(ConfirmDialog));

export default (confirmation, options = {}) => {
  // You can pass whatever you want to the component. These arguments will be your Component's props
  return confirmDialog({ confirmation, options });
}

