import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import { PhotoCell } from '@treeosk/common';
import queries from "../../../queries";
import SpecificCellPreview from "./SpecificCellPreview";

class PhotoCellPreview extends React.Component {

  render() {
    const { cell } = this.props;
    const defaultRender = () => <FontAwesomeIcon icon={'external-link-alt'} />;

    return (
      <SpecificCellPreview
        cell={cell}
        defaultRender={defaultRender}
        query={queries.cells.photo.get}
        renderingCondition={(photo_cell) => !!photo_cell.frame_uri}
      >
        {(iframeCell) => <PhotoCell preview={true} cell={iframeCell} /> }
      </SpecificCellPreview>
    );
  }

}

export default PhotoCellPreview;
